import '../css/profile.css';
import '../css/Subscription.css';
import React, { useState, useEffect } from 'react';
import { ImCheckmark } from "react-icons/im";

import { Outlet, Link, useNavigate } from "react-router-dom";

import { TextField, Checkbox, FormHelperText, Box } from '@mui/material';
import { FormControl, RadioGroup, FormControlLabel, Radio, Container } from '@mui/material';
import { Card, CardContent, Button, Typography, List, ListItem, ListItemText, Paper, ListItemIcon } from '@mui/material';
import Modal from '@mui/material/Modal';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import Popup from 'reactjs-popup';
import { MdBusinessCenter } from "react-icons/md";
import { IoBusiness } from "react-icons/io5";


import { PublicFooter, PublicNavbar, PublicTitle } from "../components/pagestyle";
import { lightBlue } from '@mui/material/colors';


const RoundedContainer = ({ check, text }) => {
    return (
        <div style={styles.container}>
            <span>{text}</span>
        </div>
    );
};

const styles = {
    container: {
        border: 'none',
        borderRadius: '15px',
        padding: '20px',
        fontSize: '14px',
        maxWidth: '600px',
        height: "fit-content",
        margin: '20px auto',
        backgroundColor: '#f9f9f9',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        alignItems: 'center'
    },
    checkmark: {
        color: 'green',
        fontSize: '18px',
        marginRight: '10px'
    }
};


const StyledTabs = styled((props) => (
    <Tabs
        {...props}
    />
))({
    '& .MuiTabs-indicator': {
        backgroundColor: 'transparent',
    }
});

const StyledTab = styled((props) => <Tab {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        width: '150px',
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        borderRadius: '25px',
        color: '#000',
        '&.Mui-selected': {
            color: '#fff',
            backgroundColor: 'var(--orange)',
        },
        '&.Mui-selected': {
            color: '#fff',
            backgroundColor: 'var(--orange)',
        },
    }),
);

const PlanCard = styled(Paper)(({ theme }) => ({
    height: 800,
    minWidth: 500,
    padding: 30,
    width: "35%",
    textAlign: 'center',
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    ...theme
}));


const freeTrialFeatures = [
    { check: true, text: "Personal Risk Analyst" },
    { check: true, text: "Data Dashboard" },
    { check: true, text: "Historical Data 01/2023-" },
    { check: true, text: "Companies in S&P 500" },
    { check: true, text: "Companies in S&P 400" },
    { check: true, text: "Companies in Russell 2000" },
    { check: true, text: "Other companies, up to 5600" },
    // {check: false, text: "Full Historical Data"},
    // {check: false, text: "API"},
    // {check: false, text: "New Companies Names"},
    // {check: false, text: "Private Time Series"},
]

const basicFeatures = [
    { check: true, text: "Personal Risk Analyst" },
    { check: true, text: "Data Dashboard" },
    { check: true, text: "Historical Data 01/2023-" },
    { check: true, text: "Companies in S&P 500" },
    // {check: false, text: "Companies in S&P 400"},
    // {check: false, text: "Companies in Russell 2000"},
    // {check: false, text: "Other companies, up to 5600"},
    // {check: false, text: "Full Historical Data"},
    // {check: false, text: "API"},
    // {check: false, text: "New Companies Names"},
    // {check: false, text: "Private Time Series"},
]

const proFeatures = [
    { check: true, text: "" },
    { check: true, text: "Companies in S&P 500, S&P 400, Russell 2000 + other companies up to 5600" },
    { check: true, text: "ETFs (1k+)" },
    { check: true, text: "Equity and Credit Indicators" },
    { check: true, text: "Access to BAM’s API" },
    { check: true, text: "Intelligent Reports: Customizable, Automatically generated analysis reports (not forwardable)" },
    { check: true, text: "Similar Companies" },
    { check: true, text: "Relative Value" },
    { check: true, text: "Natural Hedges" },
    { check: true, text: "Analysis of External Analysts (3k+)" },
    { check: true, text: "Smart Button" },
    { check: true, text: "Alerts" },
    { check: true, text: "Risk Metrics (VaR, ES, PTT)" },

    // {check: false, text: "Full Historical Data"},
    // {check: false, text: "API"},
    // {check: false, text: "New Companies Names"},
    // {check: false, text: "Private Time Series"}
]


const enterpriseFeatures = [
    { check: true, text: "All in Top Pro Engine plus:" },
    { check: true, text: "Custom Data" },
    { check: true, text: "Build & Distribute Your Expertise Record" },
    { check: true, text: "Distribute Customizable, Forwardable Intelligent Reports" },
    { check: true, text: "Integrations (APIs)" },
    { check: true, text: "Internal Content" },
    { check: true, text: "New Public Companies (up to 8k)" },
    { check: true, text: "Data Sciences Support" },
    { check: true, text: "IT Support" }
]

export function EnterEmailModal({ onClose }) {
    const [email, setEmail] = useState("");
    const [first, setFirst] = useState("");
    const [last, setLast] = useState("");
    const [number, setNumber] = useState("");
    const [response, setResponse] = useState("");
    const [role, setRole] = useState("");


    const handleSubmitGeneral = async (e) => {
        e.preventDefault();
        handleSubmit();
    };

    const handleSubmit = async () => {
        if (!email) {
            setResponse("Email required to submit");
            return;
        }
        if (!first) {
            setResponse("First Name required to submit");
            return;
        }
        if (!last) {
            setResponse("Last Name required to submit");
            return;
        }
        if (!number) {
            setResponse("Phone Number required to submit");
            return;
        }
        if (!role) {
            setResponse("Role required to submit");
            return;
        }
        const validateEmail = (em) => {
            return String(em)
                .toLowerCase().match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        };
        const validatePhoneNumber = (phone) => {
            return String(phone).match(/^\d{10,11}$/);
        };
        if (email && !validateEmail(email)) {
            setResponse("Valid email required to submit");
            return;
        }
        if (number && !validatePhoneNumber(number)) {
            setResponse("Valid phone number required to submit");
            return;
        }
        window.dataLayer.push({ 'event': 'resource_center_email_access' });
        try {
            let res = await fetch(`/api/public/request-pricing`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email,
                    first: first,
                    last: last,
                    number: number,
                    role: role, // Include role in request body
                    url: window.location.href,
                }),
            });
            //captchaRef.current.reset();
            if (res.status === 200) {
                setEmail("");
                setFirst("");
                setLast("");
                setNumber("");
                setRole(""); // Reset role
                setResponse("We will contact you soon! Thank you.");
            } else if (res.status === 403 || res.status === "403") {
                setResponse("Error: please try again or contact us directly");
            } else {
                setResponse(res, res.status);
            }
        } catch (err) {
            setResponse(`Error: ${err.message}`);
            console.error("Error during fetch:", err);
        }
    };

    return (
        <div className="home-chat-modal">
            {/* <button className="modal-close" onClick={onClose}>Close</button> */}
            <h3>Enter Email to Receive BAM Pricing</h3>
            <form className="pricing-form" onSubmit={handleSubmitGeneral}>
                <input type="text" value={email} id="email" onChange={(e) => setEmail(e.target.value)} placeholder="Business Email" />
                <input type="text" value={first} id="first" onChange={(e) => setFirst(e.target.value)} placeholder="First Name" />
                <input type="text" value={last} id="last" onChange={(e) => setLast(e.target.value)} placeholder="Last Name" />
                <input type="text" value={number} id="number" onChange={(e) => setNumber(e.target.value)} placeholder="Phone Number" />
                <select value={role} id="role" className="pricing-form" onChange={(e) => setRole(e.target.value)}>
                    <option value="" disabled>Role</option>
                    <option value="Corporate">Corporate</option>
                    <option value="Buy Side">Buy Side</option>
                    <option value="Sell Side">Sell Side</option>
                    <option value="Consultant">Consultant</option>
                    <option value="Other">Other</option>
                </select>
                <button type="submit">Submit</button>
                <div className="message">{response ? <p>{response}</p> : null}</div>
            </form>
        </div>
    );
}

function SubscriptionPanel({ subscription, stripeCustomer, handleOpenModal }) {
    const [message, setMessage] = useState('');
    const [verificationCode, setVerificationCode] = React.useState('');
    const [phoneNumber, setPhoneNumber] = React.useState('')
    const [openPhoneNumber, setOpenPhoneNumber] = React.useState(false);
    const [openVerify, setOpenVerify] = React.useState(false);
    const [tabValue, setTabValue] = React.useState(1);

    const handleChangePhone = (newValue) => {
        setPhoneNumber(newValue)
    }

    var subdomain = "";
    if (window.location.hostname.includes("researchdemo")) {
        subdomain = "researchdemo."
    }
    var api_url = `https://${subdomain}bam.money/api`
    if (window.location.hostname.includes("localhost")) {
        api_url = `http://localhost:3001/api`
    }

    const handleSignUp = async () => {
        window.location = `${api_url}/sign-up`
    };

    const handleOpen = () => setOpenPhoneNumber(true);
    const handleClose = () => setOpenPhoneNumber(false);
    const handleOpenVerify = () => setOpenVerify(true);
    const handleCloseVerify = () => setOpenVerify(false);

    const handleVerifyPhone = async () => {
        if (!matchIsValidTel(phoneNumber)) {
            alert("Phone number not valid")
            return
        }
        const response = await fetch("/api/account/verify-phone", {
            method: "POST",
            body: JSON.stringify({
                phone: phoneNumber
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (response.ok) {
            window.dataLayer.push({ 'event': 'phone_verification_success' });
            handleOpenVerify();
        } else {
            console.error("API call failed");
        }

    }

    const handleSubmitCode = async () => {
        const response = await fetch("/api/account/verify-phone-code", {
            method: "POST",
            body: JSON.stringify({
                verificationCode: verificationCode
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (response.ok) {
            window.location.reload();
        } else {
            const responseData = await response.json();
            if (responseData.response === 'Invalid verification code') {
                alert('Wrong verification code');
            } else {
                console.error("API call failed");
            }
        }

    }

    const handleTrialSubmit = async (event) => {
        window.dataLayer.push({ 'event': 'subscribe_trial' });
        const response = await fetch("/api/account/subscription", {
            method: "POST",
            body: JSON.stringify({
                type: 'subscribe_trial'
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (response.ok) {
            window.location.reload();
        } else if (response.status === 401) {
            const responseData = await response.json();
            if (responseData.response === 'email not verified') {
                alert('Please verify your email before subscribing.');
            } else {
                if (responseData.response === 'phone number not verified') {
                    handleOpen()
                } else {
                    console.error("API call failed");
                }
            }
        } else {
            console.error("API call failed");
        }
    };

    const handleSubscribePlan = async (type, yearly_or_monthly) => {
        var dur = 'monthly'
        if (yearly_or_monthly == 1) {
            dur = 'yearly'
        }
        window.dataLayer.push({ 'event': `subscribe_plan-${type}-${dur}` });
        const response = await fetch("/api/account/subscription", {
            method: "POST",
            body: JSON.stringify({
                type: type,
                variant: dur
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (response.status === 200) {
            const data = await response.json();
            const redirectUrl = data.redirect_url;
            window.location.href = redirectUrl;
        } else if (response.status === 401) {
            const responseData = await response.json();
            if (responseData.response === 'email not verified') {
                alert('Please verify your email before subscribing.');
            } else {
                console.error("API call failed");
            }
        } else {
            console.error("API call failed");
        }

    };

    const handleManageSubscription = async (event) => {
        window.dataLayer.push({ 'event': 'manage_subscription' });
        const response = await fetch("/api/account/subscription", {
            method: "POST",
            body: JSON.stringify({
                type: 'portal'
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (response.status === 200) {
            const data = await response.json();
            const redirectUrl = data.redirect_url;
            window.location.href = redirectUrl;
        }
    };

    const handleEnterpriseSubmit = (event) => {
        window.dataLayer.push({ 'event': "enterprise_submitted" });
        if (supportRef.current) {
            supportRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleSupportSubmit = () => {
        window.dataLayer.push({ 'event': "support_submitted" });
        fetch('/api/account/support', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'text': message
            }),
        })
            .then(() => {
                setMessage("");
                alert("submit success!")
            })
            .catch(() => {
                alert("Submit failed. Please try again or email us")
            });
    };

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };
    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };


    const supportRef = React.useRef(null);
    return (
        <>
            {/* {((subscription.response === "subscribed" && (subscription.subscription !== "pulse_free"))) &&
                <StyledTabs value={tabValue} onChange={handleChangeTab} centered>
                    <StyledTab label="Monthly" />
                    <StyledTab label="Yearly" />
                </StyledTabs>} */}
            <Stack
                justifyContent="center"
                alignItems="flex-end"
                margin="auto"
                spacing={3}
                padding={3}
            >
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    margin="auto"
                    spacing={3}
                    style={{ margin: "0 auto" }}
                >
                    {/* logic to determine whether to display free trial card*/}
                    {/* {(!(subscription.response === "subscribed" && (subscription.subscription !== "pulse_free"))) && 
                    
                    <PlanCard elevation={7} square={false}>
                        <Typography variant="h5" component="div">
                            Free Trial
                        </Typography>
                        <div style={{ flexGrow: 1, marginTop:10}}>
                            <List dense={true} disablePadding={true}>
                                {freeTrialFeatures.map((feature, index) => (
                                    <ListItem key={index} alignItems='center'>
                                        <ListItemIcon>
                                            {feature.check ? <CheckRoundedIcon /> : <CloseRoundedIcon />}
                                        </ListItemIcon>
                                        <ListItemText fontSize={7} primary={feature.text} />
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                        <Typography variant="h6" style={{ marginTop: '1rem', marginBottom: '1rem'}}>
                            7 days trial
                        </Typography>
                        {(subscription.response === "no subscription" || subscription.response === 'email not verified') &&
                            <Button
                                fullWidth={true}
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={handleTrialSubmit}
                            >
                                Trial
                            </Button>
                        }
                        {subscription.response === "subscribed" && 
                            (
                                subscription.subscription === "pulse_free" ? (
                                  <Button
                                    fullWidth={true}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    disabled={true}
                                  >
                                    {Math.floor((new Date(subscription.valid_till) - new Date()) / (1000 * 60 * 60 * 24))} days left
                                  </Button>
                                ) : (
                                  <Button
                                    fullWidth={true}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    disabled={true}
                                  >
                                    Trial
                                  </Button>
                                )
                            )
                        }
                    </PlanCard>} */}

                    {/* <PlanCard elevation={7} square={false}>
                        <Typography variant="h5" component="div">
                            2-day Pass per month
                        </Typography>
                        <div style={{ flexGrow: 1 , marginTop:10}}>
                            <List dense={true} disablePadding={true}>
                                {proFeatures.map((feature, index) => (
                                    <ListItem key={index} alignItems='center'>
                                        <ListItemIcon>
                                            {feature.check ? <CheckRoundedIcon /> : <CloseRoundedIcon />}
                                        </ListItemIcon>
                                        <ListItemText primary={feature.text} />
                                    </ListItem>
                                ))}
                            </List>
                        </div>

                        {((subscription.response === "subscribed" && (subscription.subscription !== "pulse_free"))) && <Typography variant="h6" style={{ marginTop: '1rem', marginBottom: '1rem'}}>
                            <div style={{ textDecoration: 'line-through' }}>
                                $63 / month
                            </div>
                            <div>
                                Limited time early adopter
                            </div>
                            {tabValue == 0 &&
                                "$29 / month"
                            }
                            {tabValue == 1 &&
                                "$19 / month"
                            }
                        </Typography>}
                        {stripeCustomer.response === "no stripe customerId" && 
                            <Button
                                fullWidth={true}
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={() => handleSubscribePlan('subscribe_daily', tabValue)}
                            >
                                Subscribe
                            </Button>
                        }
                        {stripeCustomer.response === "has stripe customerId" && 
                            <Button
                                fullWidth={true}
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={handleManageSubscription}
                            >
                                Manage Subscription
                            </Button>
                        }
                    </PlanCard> */}

                    <PlanCard elevation={7} square={false}>

                        <Typography variant="h5" component="div">
                            Professional
                        </Typography>
                        <Typography variant="h8" component="div">
                            One Seat
                        </Typography>
                        <RoundedContainer text={"The most effective pre-trade validation insights available. It gives you deeper insights and at the same time decreases your time spent researching."} />
                        <div style={{ flexGrow: 1, marginTop: 10 }}>
                            <List dense={true} disablePadding={true}>
                                {proFeatures.map((feature, index) => (
                                    <ListItem key={index} alignItems='center'>
                                        {/* <ListItemIcon>
                                            {feature.check ? <CheckRoundedIcon /> : <CloseRoundedIcon />}
                                        </ListItemIcon> */}
                                        <ListItemText primary={feature.text} />
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                        {((subscription.response === "subscribed" && (subscription.subscription !== "pulse_free"))) && <Typography variant="h6" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                            <div style={{ textDecoration: 'line-through' }}>
                                $299 / month
                            </div>
                            <div>
                                Limited time early adopter
                            </div>
                            {tabValue == 0 &&
                                "$139 / month"
                            }
                            {tabValue == 1 &&
                                "$99 / month"
                            }
                        </Typography>}
                        {stripeCustomer.response === "no stripe customerId" &&
                            <Button
                                fullWidth={true}
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={() => handleSubscribePlan('subscribe_premium', tabValue)}
                            >
                                Subscribe
                            </Button>
                        }
                        {stripeCustomer.response === "has stripe customerId" &&
                            <Button
                                fullWidth={true}
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={handleManageSubscription}
                            >
                                Manage Subscription
                            </Button>
                        }
                        {subscription.response !== 'email not verified'
                            && subscription.response !== "subscribed"
                            && subscription.response !== "no subscription"
                            &&
                            <Button
                                fullWidth={true}
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={handleSignUp}
                            >
                                Trial
                            </Button>
                        }
                        <br></br>
                        {!((subscription.response === "subscribed" && (subscription.subscription !== "pulse_free"))) && <Button
                            fullWidth={true}
                            color="primary"
                            variant="contained"
                            size="large"
                            onClick={handleOpenModal}
                        >
                            Request for Pricing
                        </Button>}
                    </PlanCard>

                    <PlanCard elevation={7} square={false} sx={{ backgroundColor: "#D4F1F4" }}>

                        <Typography variant="h5" component="div">
                            Enterprise
                        </Typography>
                        <Typography variant="h8" component="div">
                            Twenty Seats
                        </Typography>
                        <RoundedContainer text={"Position your firm at the forefront of financial research with BAM’s Enterprise Plan. This plan offers extensive capabilities to gain deeper insights while minimizing research time, enabling your team to add and make informed decisions faster."} />
                        <div style={{ flexGrow: 1, marginTop: 10 }}>
                            <List dense={true} disablePadding={true}>
                                {enterpriseFeatures.map((feature, index) => (
                                    <ListItem key={index} alignItems='center'>
                                        {/* <ListItemIcon>
                                            {feature.check ? <CheckRoundedIcon /> : <CloseRoundedIcon />}
                                        </ListItemIcon> */}
                                        <ListItemText primary={feature.text} />
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                        {((subscription.response === "subscribed" && (subscription.subscription !== "pulse_free"))) && <Typography variant="h6" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                            Starting at $48,000 / year
                        </Typography>}
                        {!((subscription.response === "subscribed" && (subscription.subscription !== "pulse_free"))) && <Button
                            fullWidth={true}
                            color="primary"
                            variant="contained"
                            size="large"
                            onClick={handleOpenModal}
                        >
                            Request for Pricing
                        </Button>}
                    </PlanCard>
                </Stack>
                <hr />
                <PlanCard elevation={7} square={false} ref={supportRef} style={{ height: "fit-content", alignSelf: "right" }}>
                    <div className='pricing-form'><h3>Contact Support</h3></div>
                    <Typography variant="h6" style={{ marginTop: '1rem' }}>
                        Let us know your question, and we will get back to you
                    </Typography>
                    <TextField
                        label="Message"
                        multiline
                        rows={4}
                        placeholder="Let us know your problem"
                        value={message}
                        onChange={handleMessageChange}
                    />
                    <div className="pricing-form">
                        <button
                            fullWidth={true}
                            color="primary"
                            variant="contained"
                            size="large"
                            onClick={handleSupportSubmit}
                        >
                            Submit
                        </button>
                    </div>
                </PlanCard>
            </Stack>
            <Modal
                open={openPhoneNumber}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 800,
                    bgcolor: 'background.paper',
                    borderRadius: 5,
                    boxShadow: 24,
                    p: 4,
                }}>
                    <img className="sidebar-logo" src="/images/logo-dark-new.png" alt="" />
                    <Typography variant="h5" component="div">
                        Please verify your phone number before subscribing to free trial
                    </Typography>
                    <Typography variant="h7" component="div">
                        By verifying, you agreed to receive a message with a 6-digit code to verify
                        your phone number. Message and phone rates may apply. Reply STOP to unsubscribe.
                    </Typography>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        margin="auto"
                        spacing={3}
                    >
                        <MuiTelInput value={phoneNumber} onChange={handleChangePhone} defaultCountry="US" forceCallingCode />
                        <Button
                            color="primary"
                            variant="contained"
                            size="large"
                            onClick={handleVerifyPhone}
                        >
                            Verify
                        </Button>
                    </Stack>
                </Box>
            </Modal>
            <Modal
                open={openVerify}
                onClose={handleCloseVerify}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 300,
                    bgcolor: 'background.paper',
                    borderRadius: 5,
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        margin="auto"
                        spacing={3}
                    >
                        <TextField
                            width={70}
                            label="Verification Code"
                            placeholder="Enter 6-digits verification code"
                            value={verificationCode}
                            onChange={(e) => setVerificationCode(e.target.value)}
                        />
                        <Button
                            color="primary"
                            variant="contained"
                            size="large"
                            onClick={handleSubmitCode}
                        >
                            Verify
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        </>
    );
}

const PricingTable = ({ subscription, stripeCustomer, handleOpenModal }) => {
    const [message, setMessage] = useState('');
    const [verificationCode, setVerificationCode] = React.useState('');
    const [phoneNumber, setPhoneNumber] = React.useState('')
    const [openPhoneNumber, setOpenPhoneNumber] = React.useState(false);
    const [openVerify, setOpenVerify] = React.useState(false);
    const [tabValue, setTabValue] = React.useState(1);

    const handleChangePhone = (newValue) => {
        setPhoneNumber(newValue)
    }

    var subdomain = "";
    if (window.location.hostname.includes("researchdemo")) {
        subdomain = "researchdemo."
    }
    var api_url = `https://${subdomain}bam.money/api`
    if (window.location.hostname.includes("localhost")) {
        api_url = `http://localhost:3001/api`
    }

    const handleSignUp = async () => {
        window.location = `${api_url}/sign-up`
    };

    const handleOpen = () => setOpenPhoneNumber(true);
    const handleClose = () => setOpenPhoneNumber(false);
    const handleOpenVerify = () => setOpenVerify(true);
    const handleCloseVerify = () => setOpenVerify(false);

    const handleVerifyPhone = async () => {
        if (!matchIsValidTel(phoneNumber)) {
            alert("Phone number not valid")
            return
        }
        const response = await fetch("/api/account/verify-phone", {
            method: "POST",
            body: JSON.stringify({
                phone: phoneNumber
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (response.ok) {
            window.dataLayer.push({ 'event': 'phone_verification_success' });
            handleOpenVerify();
        } else {
            console.error("API call failed");
        }

    }

    const handleSubmitCode = async () => {
        const response = await fetch("/api/account/verify-phone-code", {
            method: "POST",
            body: JSON.stringify({
                verificationCode: verificationCode
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (response.ok) {
            window.location.reload();
        } else {
            const responseData = await response.json();
            if (responseData.response === 'Invalid verification code') {
                alert('Wrong verification code');
            } else {
                console.error("API call failed");
            }
        }

    }

    const handleTrialSubmit = async (event) => {
        window.dataLayer.push({ 'event': 'subscribe_trial' });
        const response = await fetch("/api/account/subscription", {
            method: "POST",
            body: JSON.stringify({
                type: 'subscribe_trial'
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (response.ok) {
            window.location.reload();
        } else if (response.status === 401) {
            const responseData = await response.json();
            if (responseData.response === 'email not verified') {
                alert('Please verify your email before subscribing.');
            } else {
                if (responseData.response === 'phone number not verified') {
                    handleOpen()
                } else {
                    console.error("API call failed");
                }
            }
        } else {
            console.error("API call failed");
        }
    };

    const handleSubscribePlan = async (type, yearly_or_monthly) => {
        console.log(type);
        var dur = 'monthly'
        if (yearly_or_monthly == 1) {
            dur = 'yearly'
        }
        window.dataLayer.push({ 'event': `subscribe_plan-${type}-${dur}` });
        const response = await fetch("/api/account/subscription", {
            method: "POST",
            body: JSON.stringify({
                type: type,
                variant: dur
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (response.status === 200) {
            const data = await response.json();
            const redirectUrl = data.redirect_url;
            window.location.href = redirectUrl;
        } else if (response.status === 401) {
            const responseData = await response.json();
            if (responseData.response === 'email not verified') {
                alert('Please verify your email before subscribing.');
            } else {
                console.error("API call failed");
            }
        } else {
            console.error("API call failed");
        }

    };


    const handleSubscribeInvoicePlan = async (type, yearly_or_monthly) => {
        var dur = 'monthly';
        if (yearly_or_monthly == 1) {
            dur = 'yearly';
        }
        window.dataLayer.push({ 'event': `subscribe_plan-${type}-${dur}` });
    
        console.log('Sending request with:', { type: type, variant: dur });  // Log the request payload
    
        const response = await fetch("/api/account/invoice", {
            method: "POST",
            body: JSON.stringify({
                type: type,
                variant: dur
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });
    
        if (response.status === 200) {
            const data = await response.json();
            const redirectUrl = data.hosted_invoice_url;
            window.location.href = redirectUrl;
        } else if (response.status === 401) {
            const responseData = await response.json();
            if (responseData.response === 'email not verified') {
                alert('Please verify your email before subscribing.');
            } else {
                console.error("API call failed");
            }
        } else {
            console.error("API call failed");
        }
    };
    
    

    const handleManageSubscription = async (event) => {
        window.dataLayer.push({ 'event': 'manage_subscription' });
        const response = await fetch("/api/account/subscription", {
            method: "POST",
            body: JSON.stringify({
                type: 'portal'
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (response.status === 200) {
            const data = await response.json();
            const redirectUrl = data.redirect_url;
            window.location.href = redirectUrl;
        }
    };

    const handleEnterpriseSubmit = (event) => {
        window.dataLayer.push({ 'event': "enterprise_submitted" });
        if (supportRef.current) {
            supportRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleSupportSubmit = () => {
        window.dataLayer.push({ 'event': "support_submitted" });
        fetch('/api/account/support', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'text': message
            }),
        })
            .then(() => {
                setMessage("");
                alert("submit success!")
            })
            .catch(() => {
                alert("Submit failed. Please try again or email us")
            });
    };

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };
    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };


    const supportRef = React.useRef(null);
    const professionalFeatures = [
            { check: true, text: "" },
            { check: true, text: "Companies in S&P 500, S&P 400, Russell 2000 + other companies from 5600+" },
            { check: true, text: "ETFs (1000+)" },
            { check: true, text: "Equity and Credit Indicators" },
            { check: true, text: "Access to BAM’s API" },
            { check: true, text: "Intelligent Reports: Customizable, Automatically generated analysis reports (not forwardable)" },
            { check: true, text: "Similar Companies" },
            { check: true, text: "Relative Value" },
            { check: true, text: "Natural Hedges" },
            { check: true, text: "Analysis of External Analysts (3000+)" },
            { check: true, text: "Smart Button" },
            { check: true, text: "Alerts" },
            { check: true, text: "Risk Metrics (VaR, ES, PTT)" },
    ];


    const enterpriseFeatures = [
        { check: true, text: "" },
            { check: true, text: "Companies in S&P 500, S&P 400, Russell 2000 + other companies from 5600+" },
            { check: true, text: "ETFs (1000+)" },
            { check: true, text: "Equity and Credit Indicators" },
            { check: true, text: "Access to BAM’s API" },
            { check: true, text: "Intelligent Reports: Customizable, Automatically generated analysis reports (not forwardable)" },
            { check: true, text: "Similar Companies" },
            { check: true, text: "Relative Value" },
            { check: true, text: "Natural Hedges" },
            { check: true, text: "Analysis of External Analysts (3000+)" },
            { check: true, text: "Smart Button" },
            { check: true, text: "Alerts" },
            { check: true, text: "Risk Metrics (VaR, ES, PTT)" },
        { check: true, text: "Full Historical Data" },
        { check: true, text: "Data Platform for Custom Models" },
        { check: true, text: "Build & Distribute Your Expertise Record" },
        { check: true, text: "Ability to Forward Intelligent Reports" },
        { check: true, text: "APIs to Upload and Download data" },
        { check: true, text: "Extra Public Companies (up to 8000)" },
        { check: true, text: "Individualized Data Science Support" },
        { check: true, text: "IT Support Crafted to your Needs" },
    ];

    return (
        <div className="pricing-container">
            <div className="pricing-table">
                <table>
                    <thead>
                        <tr>
                            <th className="feature-column" style={{ fontWeight: "normal", border: "none" }}>
                                <Typography variant="h5" component="div">

                                </Typography></th>
                            <th className="plan-column" style={{ fontWeight: "normal", border: "none" }}>
                                <Typography variant="h5" component="div">
                                    Enterprise Plan
                                </Typography>
                                <Typography variant="h8" component="div">
                                    Twenty Seats
                                </Typography>
                                <RoundedContainer text={"Position your firm at the forefront of financial research with BAM’s Enterprise Plan. This plan offers extensive capabilities to gain deeper insights while minimizing research time, enabling your team to add and make informed decisions faster."} />
                            </th>
                            <th className="plan-column" style={{ fontWeight: "normal", border: "none" }}>
                                <Typography variant="h5" component="div">
                                    Professional Plan
                                </Typography>
                                <Typography variant="h8" component="div">
                                    One Seat
                                </Typography>
                                <RoundedContainer text={"The most effective pre-trade validation insights available. It gives you deeper insights and at the same time decreases your time spent researching."} />
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {enterpriseFeatures.map((feature, index) => (
                            <tr key={index}>
                                <td>{feature.text}</td>
                                <td>{feature.check ? <span className="check-mark"><ImCheckmark /></span> : ""}</td>
                                <td>{professionalFeatures[index] && professionalFeatures[index].check ? <span className="check-mark"><ImCheckmark /></span> : ""}</td>
                            </tr>
                        ))}
                        {professionalFeatures.slice(enterpriseFeatures.length).map((feature, index) => (
                            <tr key={index + enterpriseFeatures.length}>
                                <td>{feature.text}</td>
                                <td>{feature.check ? <span className="check-mark"><ImCheckmark /></span> : ""}</td>
                                <td></td>
                            </tr>
                        ))}
                        <td style={{ border: "none" }}></td>
                        <td style={{ border: "none" }}>{((subscription.response === "subscribed" && (subscription.subscription !== "pulse_free"))) && <Typography variant="h6" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                            Starting at $48,000 / year
                        </Typography> }
                        {stripeCustomer.response === "no stripe customerId" &&
                                <div className="pricing-form">
                                    <button
                                        fullWidth={true}
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        onClick={() => handleSubscribePlan('subscribe_premium_enterprise', tabValue)}
                                    >
                                        Subscribe
                                    </button>
                                </div>
                            }
                            {stripeCustomer.response === "no stripe customerId" &&
                                <div className="pricing-form">
                                    <button
                                        fullWidth={true}
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        onClick={() => handleSubscribeInvoicePlan('subscribe_premium_enterprise', tabValue)}
                                    >
                                        Subscribe: Invoice
                                    </button>
                                </div>
                            }
                        </td>
                        <td style={{ border: "none" }}>                       
                             {((subscription.response === "subscribed" && (subscription.subscription !== "pulse_free"))) && <Typography variant="h6" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        
                      
                                $2,400 / year
                        </Typography>}
                            {stripeCustomer.response === "no stripe customerId" &&
                                <div className="pricing-form">
                                    <button
                                        fullWidth={true}
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        onClick={() => handleSubscribePlan('subscribe_premium', tabValue)}
                                    >
                                        Subscribe
                                    </button>
                                </div>
                            }
                            {stripeCustomer.response === "no stripe customerId" &&
                                <div className="pricing-form">
                                    <button
                                        fullWidth={true}
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        onClick={() => handleSubscribeInvoicePlan('subscribe_premium', tabValue)}
                                    >
                                        Subscribe: Invoice
                                    </button>
                                </div>
                            }
                            {stripeCustomer.response === "has stripe customerId" &&
                                <div className="pricing-form">
                                    <button
                                        fullWidth={true}
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        onClick={handleManageSubscription}
                                    >
                                        Manage Subscription
                                    </button>
                                </div>
                            }
                            {subscription.response !== 'email not verified'
                                && subscription.response !== "subscribed"
                                && subscription.response !== "no subscription"
                                &&
                                <div className="pricing-form">
                                    <button
                                        fullWidth={true}
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        onClick={handleSignUp}
                                    >
                                        Free Trial
                                    </button>
                                </div>
                            }</td>
                    </tbody>
                </table>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "200px", padding: "20px", justifyContent: "center" }}>
                    <div className="home-chat-modal">

                        <h3>Contact Support</h3>

                        <br></br>

                        Let us know your question, and we will get back to you.
                        <br></br>
                        <br></br>

                        <TextField
                            label="Message"
                            multiline
                            rows={4}
                            placeholder="Let us know your problem"
                            value={message}
                            onChange={handleMessageChange}
                        />
                        <div className="pricing-form">
                            <button
                                fullWidth={true}
                                color="primary"
                                variant="contained"
                                size="large"
                                onClick={handleSupportSubmit}
                            >
                                Submit
                            </button>
                        </div>

                    </div>

                    {!((subscription.response === "subscribed" && (subscription.subscription !== "pulse_free"))) &&

                        // <div className='pricing-form'><button
                        //             // fullWidth={true}
                        //             // color="primary"
                        //             // variant="contained"
                        //             // size="large"
                        //             onClick={handleOpenModal}
                        //         >
                        //             Request for Pricing
                        //         </button> </div>
                        <EnterEmailModal />
                    }

                </div>
            </div>
        </div>
    );
};
export default function Subscription() {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = (e) => {
        e.preventDefault();  // Prevent the default anchor behavior
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const [subscription, setSubscription] = useState({})
    const [stripeCustomer, setStripeCustomer] = useState({})

    window.document.title = "BAM.money | Subscription";

    const checkIfSubscriptionActive = () => {
        fetch('/api/account/subscription', {})
            .then((response) => response.json())
            .then((json) => {
                setSubscription(json)
            })
            .catch(() => { });
    }

    const checkHasStripeProfile = () => {
        fetch('/api/account/subscription/stripe', {})
            .then((response) => response.json())
            .then((json) => {
                setStripeCustomer(json)
            })
            .catch(() => { });
    }

    useEffect(() => {
        checkIfSubscriptionActive()
        checkHasStripeProfile()
    }, []);

    return (
        <>
            <PublicNavbar forceRedirectIfNoAccount={false} forceRedirectIfSubscriptionNotActive={false} />
            <PublicTitle title="Account Subscription" />
            <h2>Leverage cutting-edge AI risk management engine for trading, crafted by seasoned risk managers for elite traders. </h2>
            <h2>Gain the advanced signals you need to mitigate risks, identify opportunities, and excel in this data-driven industry.</h2>
            <PricingTable subscription={subscription} stripeCustomer={stripeCustomer} handleOpenModal={handleOpenModal} />
            {/* <SubscriptionPanel subscription={subscription} stripeCustomer={stripeCustomer} handleOpenModal={handleOpenModal} />
            <Popup open={isModalOpen} onClose={handleCloseModal} modal closeOnDocumentClick>
                <EnterEmailModal onClose={handleCloseModal} />
            </Popup> */}
            <PublicFooter />
        </>
    );
};