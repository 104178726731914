import '../css/header.css';
import { isMobile } from 'react-device-detect';
import React, { useState, useEffect } from 'react'
import { Outlet, Link, useNavigate } from "react-router-dom";

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AppBar, Toolbar, Menu, Drawer } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import { DarkModeSwitch } from 'react-toggle-dark-mode';

export function Authenticate({ forceRedirectIfNoAccount = true, forceRedirectIfSubscriptionNotActive = true }) {
    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [loggedInButNotRegistered, setLoggedInButNotRegistered] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [dropdownOpenMobile, setDropdownOpenMobile] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    var subdomain = "";
    if (window.location.hostname.includes("researchdemo")) {
        subdomain = "researchdemo."
    }
    var api_url = `https://${subdomain}bam.money/api`
    if (window.location.hostname.includes("localhost")) {
        api_url = `http://localhost:3001/api`
    }

    function checkPath(path) {
        return window.location.pathname.includes(path) ? " highlighted-button" : ""
    }

    const handleLogin = async () => {
        window.location = `${api_url}/login`
    };
    const handleSignUp = async () => {
        window.location = `${api_url}/sign-up`
    };
    const handleLogout = () => {
        localStorage.setItem("user-is-logged-in-bam-money", "No")
        window.location = `${api_url}/logout`
    };

    const checkIfLoggedIn = () => {
        if (("Yes" == localStorage.getItem("user-is-logged-in-bam-money"))
            && (Date.now() - (localStorage.getItem("user-time-log-in-bam-money") ? localStorage.getItem("user-time-log-in-bam-money") : 0) < 120000)) {
            setUsername(localStorage.getItem("username-bam-money"))
            if (forceRedirectIfSubscriptionNotActive) checkIfSubscriptionActive()
            return;
        }
        fetch('/api/account', {})
            .then((response) => response.json())
            .then((json) => {
                if (json.response === "not registered") {
                    setLoggedInButNotRegistered(true)
                    localStorage.setItem("user-is-logged-in-bam-money", "Need Registration")
                    localStorage.setItem("user-time-log-in-bam-money", Date.now())
                    if (forceRedirectIfNoAccount) {
                        window.location.href = "/register";
                    }
                }
                if (json.response === "registered") {
                    window.dataLayer.push({ 'event': 'login' });
                    localStorage.setItem("user-is-logged-in-bam-money", "Yes")
                    localStorage.setItem("user-time-log-in-bam-money", Date.now())
                    localStorage.setItem("username-bam-money", json.name)
                    setUsername(json.name)
                    if (forceRedirectIfSubscriptionNotActive) checkIfSubscriptionActive()
                } else {
                    setUsername("")
                    localStorage.setItem("user-is-logged-in-bam-money", "No")
                    localStorage.setItem("user-time-log-in-bam-money", Date.now())
                }
            })
            .catch(() => setUsername(""));
    }

    const checkIfSubscriptionActive = () => {
        fetch('/api/account/subscription', {})
            .then((response) => response.json())
            .then((json) => {
                if (json.response !== "subscribed") {
                    window.location.href = "/subscription";
                }
            })
            .catch(() => { });
    }

    useEffect(() => {
        checkIfLoggedIn();
    }, []);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isDarkMode, setDarkMode] = useState(localStorage.getItem("bam-money-site-theme") ? (localStorage.getItem("bam-money-site-theme") == 'dark') : false);

    useEffect(() => {
        document.documentElement.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');
        localStorage.setItem("bam-money-site-theme", isDarkMode ? 'dark' : 'light');
    }, [isDarkMode])

    return (
        <AppBar position="static" sx={{backgroundColor: "transparent", boxShadow: "none"}}>
            <Toolbar>
                <Link to="/"><img className="public-logo" src="/images/logo-dark-new.png" alt="" /></Link>

                <Box display="flex" justifyContent="flex-start" flexGrow={1}> </Box>
                <Box display="flex" justifyContent="flex-end">
                    {/* On mobile, collapse into hamburger */}
                    {isMobile && (
                        <>
                            <MenuIcon onClick={() => setMenuOpen(!menuOpen)} className="hamburger-button" />

                            {/* Recreate the navbar in a drawer if mobile */}
                            <Drawer
                                anchor="right"
                                variant="temporary"
                                open={menuOpen}
                                onClose={() => setMenuOpen(false)}
                                onOpen={() => setMenuOpen(true)}
                                ModalProps={{disableScrollLock: true}}
                            >
                                <Box display="flex" flexDirection="column">
                                    <Box display="flex" justifyContent="flex-end">
                                        <CloseIcon onClick={() => setMenuOpen(false)} className="drawer-close" />
                                    </Box>

                                    <Link to="/resources">
                                        <button className={"mobile-authenticate-button" + checkPath("resources")}>
                                            Resource Center
                                        </button>
                                    </Link>


                                    {username !== "" && (
                                        <>
                                            <Link to="/tradingpulse">
                                                <button className={"mobile-authenticate-button" + checkPath("tradingpulse")}>
                                                    TradingPulse
                                                </button>
                                            </Link>

                                            <button className="mobile-authenticate-button" onClick={() => setDropdownOpenMobile(!dropdownOpenMobile)}>
                                                <span className="username-with-icon">
                                                    <div className="username-div">
                                                        {username}
                                                    </div>
                                                    <KeyboardArrowDownIcon />
                                                </span>
                                            </button>
                                            <>
                                                {dropdownOpenMobile && (
                                                    <>
                                                        <Link to="/profile">
                                                            <button className={"mobile-dropdown-button" + checkPath("profile")}>
                                                                Profile
                                                            </button>
                                                        </Link>
                                                        <Link to="/custom-data">
                                                            <button className={"mobile-dropdown-button" + checkPath("custom-data")}>
                                                                CustomData
                                                            </button>
                                                        </Link>
                                                        <Link to="/auto-trade">
                                                            <button className={"mobile-dropdown-button" + checkPath("auto-trade")}>
                                                                AutoTrade
                                                            </button>
                                                        </Link>
                                                        <Link to="/notifications">
                                                            <button className={"mobile-dropdown-button" + checkPath("notifications")}>
                                                                Notifications
                                                            </button>
                                                        </Link>
                                                    </>
                                                )}
                                            </>

                                            <button className="mobile-authenticate-button" onClick={handleLogout}>
                                                Logout
                                            </button>
                                        </>
                                    )}

                                    {username === "" && (
                                        <>
                                            {!loggedInButNotRegistered && (
                                                <>
                                                    <button className="mobile-authenticate-button" onClick={handleLogin}>
                                                        Login
                                                    </button>
                                                    <button className="mobile-authenticate-button" onClick={() => navigate('/contact')}>Book a Demo</button>
                                                    <button className="mobile-authenticate-button" onClick={handleSignUp}>
                                                        Try it Free
                                                    </button>
                                                </>
                                            )}
                                            {loggedInButNotRegistered && (
                                                <>
                                                    <button className="mobile-authenticate-button" onClick={handleLogout}>
                                                        Logout
                                                    </button>
                                                </>
                                            )}
                                        </>
                                    )}

                                </Box>


                            </Drawer>
                        </>
                    )}
                    {/* Desktop view */}
                    {!isMobile && (
                        <>

                            <Link to="/resources">
                                <button className={"authenticate-button" + checkPath("resources")}>
                                    Resource Center
                                </button>
                            </Link>

                            {username !== "" && (
                                <>
                                    <Link to="/tradingpulse">
                                        <button className={"authenticate-button" + checkPath("tradingpulse")}>
                                            TradingPulse
                                        </button>
                                    </Link>

                                    <Link to="/profile">
                                        <button className={"authenticate-button" + checkPath("profile")} onMouseEnter={handleClick}>
                                            {username}
                                        </button>
                                    </Link>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onMouseLeave={handleClose}
                                        onClose={handleClose}
                                        disableScrollLock={true}
                                    >
                                        <div className="button-container" onMouseLeave={handleClose}>
                                            <Link to="/profile">
                                                <button className={"dropdown-button" + checkPath("profile")}>
                                                    Profile
                                                </button>
                                            </Link>
                                            <Link to="/custom-data">
                                                <button className={"dropdown-button" + checkPath("custom-data")}>
                                                    CustomData
                                                </button>
                                            </Link>
                                            <Link to="/auto-trade">
                                                <button className={"dropdown-button" + checkPath("auto-trade")}>
                                                    AutoTrade
                                                </button>
                                            </Link>
                                            <Link to="/notifications">
                                                <button className={"dropdown-button" + checkPath("notifications")}>
                                                    Notifications
                                                </button>
                                            </Link>
                                        </div>
                                    </Menu>

                                    <button className="authenticate-button" onClick={handleLogout}>
                                        Logout
                                    </button>
                                </>
                            )}

                            {username === "" && (
                                <>
                                    {!loggedInButNotRegistered && (
                                        <>
                                            <button className="authenticate-button" onClick={handleLogin}>
                                                Login
                                            </button>
                                            <button className="authenticate-button" onClick={() => navigate('/contact')}>Book a Demo</button>
                                            <button className="authenticate-button" onClick={handleSignUp}>
                                                Try it Free
                                            </button>
                                        </>
                                    )}
                                    {loggedInButNotRegistered && (
                                        <>
                                            <button className="authenticate-button" onClick={handleLogout}>
                                                Logout
                                            </button>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}


                </Box>
                <Box>
                    <DarkModeSwitch
                        checked={isDarkMode}
                        onChange={(c) => setDarkMode(c)}
                        sunColor="var(--orange)"
                        moonColor="var(--navy)"
                        size={35}
                    />
                    </Box>
            </Toolbar>

        </AppBar>
    );
}

export function Register(props) {

    const [username, setUsername] = useState("");

    const navigate = useNavigate();

    var subdomain = "";
    if (window.location.hostname.includes("researchdemo")) {
        subdomain = "researchdemo."
    }

    var subdomain = "";
    if (window.location.hostname.includes("researchdemo")) {
        subdomain = "researchdemo."
    }
    var api_url = `https://${subdomain}bam.money/api`
    if (window.location.hostname.includes("localhost")) {
        api_url = `http://localhost:3001/api`
    }
    const handleSignUp = async () => {
        window.location = `${api_url}/sign-up`
    };

    const checkIfLoggedIn = () => {
        if (("Yes" == localStorage.getItem("user-is-logged-in-bam-money"))
            && (Date.now() - (localStorage.getItem("user-time-log-in-bam-money") ? localStorage.getItem("user-time-log-in-bam-money") : 0) < 120000)) {
            setUsername(localStorage.getItem("username-bam-money"))
            return;
        }
        fetch('/api/account', {})
            .then((response) => response.json())
            .then((json) => {
                if (json.response === "registered") {
                    window.dataLayer.push({ 'event': 'login' });
                    setUsername(json.name)
                } else setUsername("")
            })
            .catch(() => setUsername(""));
    }
    useEffect(() => {
        checkIfLoggedIn();
    }, []);

    return (
        <>
            {username === "" ? <>
                {
                    !props.noDemo && <button className="authenticate-button" onClick={() => navigate('/contact')}>Book a Demo</button>
                }
                <button
                    style={props.border ? { border: `2px solid ${props.border}` } : {}}
                    className={`authenticate-button ${props.highlight ? "highlighted-button" : ""}`} onClick={handleSignUp}>
                    {props.text ? props.text : "Register"}
                </button>
            </> : null}
        </>
    );
}

function NavBar() {
    return (
        <>
            <Link to="/par"><button className="sidebar-page">Personal Agent</button></Link>
            <Link to="/prediction-alerts"><button className="sidebar-page">Prediction Alerts</button></Link>
            <Link to="/team-alerts"><button className="sidebar-page">Team Alerts</button></Link>
            <Link to="/pricing"><button className="sidebar-page">Pricing</button></Link>
        </>
    )
}

export default function Header() {
    const [show, setShow] = useState(true);

    return (
        <>
            <div className="fixed-bounding">
                <div className="header">
                    {isMobile ? (
                        //mobile device -- hamburger menu
                        <div className="navbar">
                            <button onClick={() => setShow(!show)} className="hamburger-button"><img className="hamburger-icon" src="/images/hamburger-icon.png" alt="" /></button>
                            <img className="sidebar-logo" src="/images/logo-dark-new.png" alt="" />
                            {
                                show ?
                                    <div className="sidebar-bounding">
                                        <Link to="/"><img className="sidebar-logo" src="/images/logo-dark-new.png" alt="" /></Link>
                                        <NavBar />
                                        <Authenticate />
                                        <button onClick={() => setShow(!show)}><img className="close-icon" src="/images/close-icon.png" alt="" /></button>
                                    </div>
                                    : null
                            }
                        </div>
                    ) : (
                        //desktop
                        <>
                            <div className="navbar"><Authenticate /></div>
                            <div className="sidebar-bounding">
                                <Link to="/"><img className="sidebar-logo" src="/images/logo-dark-new.png" alt="" /></Link>
                                <NavBar />
                            </div>
                        </>
                    )}
                </div>
            </div>

            <div className="content-offset">
                <Outlet />
            </div>
        </>

    );
}
