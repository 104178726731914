import React, { useRef, useState, useEffect } from "react";
import '../css/d3.css'
import {
    select,
    line,
    area,
    curveCardinal,
    scaleLinear,
    axisBottom,
    axisLeft,
    svg,
    scaleTime,
    extent,
    timeMonth,
    timeDay
} from "d3";
import { Tooltip } from 'react-tooltip';

//chart component
export default function D3NewsFlow(props) {
    //refs
    const svgRef = useRef();
    const boundingRef = useRef();
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    function getFormattedDate(date) {
        var year = date.getFullYear();

        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        return month + '/' + day + '/' + year;
    }

    const generateData = (startDate, endDate, array, maximum) => {
        const data = [];
        const dateRange = timeDay.range(startDate, endDate);
        if (maximum == 0) {
            for (let i = 0; i < array.length; i++) {
                const valueLine = 0;
                if (dateRange[i] != undefined) {
                    data.push({
                        date: (new Date(dateRange[i])).toISOString().split('T')[0],
                        valueLine
                    });
                }
            }
        } else {
            for (let i = 0; i < array.length; i++) {
                const valueLine = array[i] / maximum;
                if (dateRange[i] != undefined) {
                    data.push({
                        date: (new Date(dateRange[i])).toISOString().split('T')[0],
                        valueLine
                    });
                }
            }
        }
        return data;
    };

    // Generate data for a specific date range and number of data points
    const startDate = new Date(props.date);
    startDate.setDate(startDate.getDate() - 30);
    const endDate = new Date(props.date);

    const data = generateData(startDate, endDate, props.data, Math.max(...props.data));

    useEffect(() => {
        function handleWindowResize() {
            setWidth(boundingRef.current.offsetWidth);
            setHeight(boundingRef.current.offsetHeight);
            svgRef.current.style.width = boundingRef.current.offsetWidth;
            svgRef.current.style.height = boundingRef.current.offsetHeight;
        }

        handleWindowResize();

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [])

    //draws chart
    useEffect(() => {
        const svg = select(svgRef.current);
        svg.selectAll("*").remove();

        const margins = props.small ? [10, 7] : [30, 20]

        const defs = svg.append("defs");
        const grad = defs
            .append('linearGradient')
            .attr('id', 'grad')
            .attr('x1', '0%')
            .attr('x2', '0%')
            .attr('y1', '0%')
            .attr('y2', '100%')
            .attr("gradientTransform", "rotate(0)");

        var colors = [['var(--steelblue)', '.5'], ['var(--steelblue)', '0']];

        grad.selectAll('stop')
            .data(colors)
            .enter()
            .append('stop')
            .style('stop-color', function (d) { return d[0]; })
            .style('stop-opacity', function (d) { return d[1]; })
            .attr('offset', function (d, i) {
                return 100 * (i / (colors.length - 1)) + '%';
            });

        //scales
        const xScale = scaleTime()
            .domain(extent(data, d => new Date(d.date)))
            .range([margins[0], width - margins[0]]);

        const yScale = scaleLinear().domain([0, 1]).range([height - margins[1], margins[1]]);
        //axes
        const xAxis = axisBottom(xScale).tickFormat(d => getFormattedDate(d));
        const yAxis = axisLeft(yScale);

        // Create a line generator
        const lineGenerator = line()
            .x(d => xScale(new Date(d.date)))
            .y(d => yScale(d.valueLine));

        // Create the line chart
        svg.append("path")
            .datum(data)
            .attr("class", "line")
            .attr("d", lineGenerator)
            .style("stroke", "var(--steelblue)")
            .style("fill", "none");

        var areaGenerator = area()
            .x(d => xScale(new Date(d.date)))
            .y0(height)
            .y1(d => yScale(d.valueLine));

        svg.append("path")
            .datum(data)
            .attr("class", "area")
            .attr("d", areaGenerator)
            .attr("fill", "url(#grad")

        // Append axes to the chart
        if (!props.small) {
            svg.append("g")
                .attr("class", "x-axis")
                .attr("transform", `translate(0,${height - margins[1]})`)
                .call(xAxis);

            svg.append("g")
                .attr("class", "y-axis")
                .attr("transform", `translate(${margins[0]},0)`)
                .attr("data-tooltip-id", "d3-pnl-tooltip")
                .attr("data-tooltip-html", "Equity Price ($)")
                .call(yAxis);

            svg.selectAll(".bubble")
                .data(data)
                .enter()
                .append("circle")
                .attr("class", "bubble")
                .attr("cx", d => xScale(new Date(d.date)))
                .attr("cy", d => yScale(d.valueLine))
                .attr("r", 5)
                .style("fill", "steelblue")
                .attr("data-tooltip-id", "d3-newsflow-tooltip")
                .attr("data-tooltip-html", d => `${d.valueLine.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]} news flow index on ${d.date}`);
        }

    }, [data, width, height]);

    return (
        <div ref={boundingRef} style={props.small ? { width: "90%", height: "80px" } : { height: "100%", width: "100%" }}>
            <div className={props.small ? "d3-bounding-anim-once" : ""}>
                <svg ref={svgRef} style={{ height: "100%", width: "100%" }} />
            </div>
            {!props.small ? <Tooltip id="d3-newsflow-tooltip" multiline={true} data-html={true} style={{ zIndex: "100" }} /> : null}
        </div>
    );
};