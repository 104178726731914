
import React, { useState, useEffect } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import { FaReceipt } from 'react-icons/fa6';

export function TickerOfTheDay({
    selectedIndices,
    selectedSectors
}) {
    const [data, setData] = useState(null)
    useEffect(() => {
        const encodedIndices = selectedIndices && selectedIndices.map(filter => encodeURIComponent(filter));
        const indicesParam = encodedIndices && encodedIndices.length > 0 ? `&indices=${encodedIndices.join(',')}` : '';
        const encodedSectors = selectedSectors && selectedSectors.map(filter => encodeURIComponent(filter));
        const sectorsParam = encodedSectors && encodedSectors.length > 0 ? `&sectors=${encodedSectors.join(',')}` : '';
        fetch(`/api/marketpulse/featured_tickers?${indicesParam}${sectorsParam}`)
            .then((response) => response.json())
            .then((json) => setData(json.data));
    }, [selectedSectors, selectedIndices]);

    return (
        <div>
            {data &&
                <div style={{
                    borderWidth: 2, borderRadius: 20, borderColor: 'var(--lightsteelblue)', borderStyle: 'solid', padding: 10
                }}>
                    <div>Daily Forward Looking Trades</div>
                    <table style={{ borderSpacing: 5 }}>
                        <tbody>
                            <tr>
                                <td></td>
                                <td>Down Ahead</td>
                                <td>Up Ahead</td>
                            </tr>
                            <tr>
                                <td>Down Yesterday</td>
                                <td>
                                    {data &&
                                        <div>
                                            <a style={{ color: 'var(--red)', textDecoration: 'none' }} href={`/tradingpulse/search/company/${data.decrease[0].lowestScore._id}`}>
                                                {data.decrease[0].lowestScore.ticker}
                                            </a>
                                            {
                                                data.decrease[0].lowestScore.analysis && Array.isArray(data.decrease[0].lowestScore.analysis) && data.decrease[0].lowestScore.analysis > 0 &&
                                                <a style={{ color: 'var(--red)', textDecoration: 'none', fontSize: "10px" }} href={`/analysis/article/${data.decrease[0].lowestScore.analysis[0].analysis_id}`}>
                                                    <FaReceipt />
                                                </a>
                                            }
                                        </div>
                                    }
                                </td>
                                <td>
                                    {data &&
                                        <div>
                                            <a style={{ color: 'var(--green)', textDecoration: 'none' }} href={`/tradingpulse/search/company/${data.decrease[0].highestScore._id}`}>
                                                {data.decrease[0].highestScore.ticker}
                                            </a>
                                            {
                                                data.decrease[0].highestScore.analysis && Array.isArray(data.decrease[0].highestScore.analysis) && data.decrease[0].highestScore.analysis > 0 &&
                                                <a style={{ color: 'var(--green)', textDecoration: 'none', fontSize: "10px" }} href={`/analysis/article/${data.decrease[0].highestScore.analysis[0].analysis_id}`}>
                                                    <FaReceipt />
                                                </a>
                                            }
                                        </div>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>Up Yesterday</td>
                                <td>
                                    {data &&
                                        <div>
                                            <a style={{ color: 'var(--red)', textDecoration: 'none' }} href={`/tradingpulse/search/company/${data.increase[0].lowestScore._id}`}>
                                                {data.increase[0].lowestScore.ticker}
                                            </a>
                                            {
                                                data.increase[0].lowestScore.analysis && Array.isArray(data.increase[0].lowestScore.analysis) && data.increase[0].lowestScore.analysis > 0 &&
                                                <a style={{ color: 'var(--red)', textDecoration: 'none', fontSize: "10px" }} href={`/analysis/article/${data.increase[0].lowestScore.analysis[0].analysis_id}`}>
                                                    <FaReceipt />
                                                </a>
                                            }
                                        </div>
                                    }
                                </td>
                                <td>
                                    {data &&
                                        <div>
                                            <a style={{ color: 'var(--green)', textDecoration: 'none' }} href={`/tradingpulse/search/company/${data.increase[0].highestScore._id}`}>
                                                {data.increase[0].highestScore.ticker}
                                            </a>
                                            {
                                                data.increase[0].highestScore.analysis && Array.isArray(data.increase[0].highestScore.analysis) && data.increase[0].highestScore.analysis > 0 &&
                                                <a style={{ color: 'var(--green)', textDecoration: 'none', fontSize: "10px" }} href={`/analysis/article/${data.increase[0].highestScore.analysis[0].analysis_id}`}>
                                                    <FaReceipt />
                                                </a>
                                            }
                                        </div>
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
        </div >
    );
}
