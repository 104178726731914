import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';

import Popup from 'reactjs-popup';
import { FaGear } from 'react-icons/fa6';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { OverallRow } from '../../pages/marketpulse';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';

import { AnalystSelectModal } from '../analystSelectModal.js';
import { TickerOfTheDay } from './TickerOfTheDay.js';

export function MarketpulseTopBar({
    setSearchQuery,
    setSelectedIndices, selectedIndices,
    setSelectedSectors, selectedSectors,
    setCurrentPage,
    regimeSettings,
    setRegimeSettings,
    setSortedColumn,
    setSortOrder,
    setThreshold,
    threshold,
    setMinPrice,
    minPrice,
    setOnlyShowFavorites,
    setOnlyShowETFs,
    setSelectedModel,
    models,
    isMobile,
    setSettingsOpen,
    hp,
    setHp,
    stateSort, setStateSort,
    stateIndicator, setStateIndicator,
    stateBHorizon, setStateBHorizon,
    isLoadingSumPNL,
    overallPnl,
    state,
    mobilePage,
    setMobilePage,
    period,
    HistoricalPeriodSlider,
    setPeriod,
    hpRaw,
    setHpRaw,
    handleHpCommit,
    cumulativePNLArray
}) {
    const [openModal, setOpenModal] = React.useState(false);
    const [searchTextField, setSearchTextField] = useState(localStorage.getItem("search-text") ? localStorage.getItem("search-text") : "");
    const [selectedFilters, setSelectedFilters] = useState(
        localStorage.getItem("search-filter") ? JSON.parse(localStorage.getItem("search-filter")) : []
    );
    const [checkedFavorites, setCheckedFavorites] = useState(localStorage.getItem("only-show-fav") && localStorage.getItem("only-show-fav") == "true" ? true : false);
    const [checkedETFs, setCheckedETFs] = useState(localStorage.getItem("only-show-etf") && localStorage.getItem("only-show-etf") == "true" ? true : false);
    const [stateType, setStateType] = useState("all");
    const [selectedModel, setSelectedModelLocal] = useState(models[0].id);
    const [minPriceRaw, setMinPriceRaw] = useState(minPrice);
    //const [regimeSettings, setRegimeSettings] = useState(localStorage.getItem("regime") || "neutral");

    const filtersList = [
        { type: "index", value: "S&P 500" },
        { type: "index", value: "S&P 400" },
        { type: "index", value: "Russell 2000" },
        { type: "sector", value: "Communication Services" },
        { type: "sector", value: "Consumer Discretionary" },
        { type: "sector", value: "Consumer Staples" },
        { type: "sector", value: "Energy" },
        { type: "sector", value: "Financials" },
        { type: "sector", value: "Health Care" },
        { type: "sector", value: "Industrials" },
        { type: "sector", value: "Information Technology" },
        { type: "sector", value: "Materials" },
        { type: "sector", value: "Real Estate" },
        { type: "sector", value: "Utilities" }
    ]

    useEffect(() => {
        localStorage.setItem("search-text", searchTextField)
        localStorage.setItem("search-filter", JSON.stringify(selectedFilters))
    }, [searchTextField, selectedFilters])

    // Debounce effect to execute search query after 3 seconds of no input change
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setSearchQuery(searchTextField);
        }, 2000); // 2 seconds debounce

        return () => clearTimeout(delayDebounceFn); // Cleanup timeout on unmount or when searchTextField changes
    }, [searchTextField]);

    useEffect(() => {
        localStorage.setItem("regime", regimeSettings);
    })

    const handleSearchInputChange = (event) => {
        setSearchTextField(event.target.value);
    };

    const RegimeToggle = () => {
        const handleRegimeChange = (newRegime) => {
            if (newRegime !== null) {
                setRegimeSettings(newRegime);
                localStorage.setItem("regime", newRegime);
            }
        };

        return (
            <div style={{ marginRight: "75px", marginBottom: "5px", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <p style={{ margin: "0", fontSize: "14px" }}>I'm feeling...</p>
                <button
                    onClick={() => handleRegimeChange('bullish')}
                    className={regimeSettings === 'bullish' ? "highlighted-button" : ""}
                    style={{ alignItems: "center" }}
                >
                    <TrendingUpIcon style={{ height: "16px" }} />
                    Bullish
                </button>
                <button
                    onClick={() => handleRegimeChange('neutral')}
                    className={regimeSettings === 'neutral' ? "highlighted-button" : ""}
                    style={{ alignItems: "center" }}
                >
                    <TrendingFlatIcon style={{ height: "16px" }} />
                    Neutral
                </button>
                <button
                    onClick={() => handleRegimeChange('bearish')}
                    className={regimeSettings === 'bearish' ? "highlighted-button" : ""}
                    style={{ alignItems: "center" }}
                >
                    <TrendingDownIcon style={{ height: "16px" }} />
                    Bearish
                </button>
            </div>
        );
    };


    const handleThresholdChange = (event, newValue) => {
        setThreshold(newValue);
        localStorage.setItem("threshold", newValue);
    };
    const handleHpChange = (event, newValue) => {
        setHp(newValue);
        localStorage.setItem("hp", newValue);
    };
    const handleMinPriceChange = (event, newValue) => {
        setMinPriceRaw(Math.pow(10, newValue) <= -2 ? -2 : Math.pow(10, newValue));

    };
    const handleMinPriceCommit = (event, newValue) => {
        setMinPriceRaw(Math.pow(10, newValue) <= -2 ? -2 : Math.pow(10, newValue));
        setMinPrice(minPriceRaw);
        localStorage.setItem("minprice", minPriceRaw);
    };

    const handleFilterChange = (event) => {
        const listOfFilters = event.target.value
        var arrselectedindices = Array.from(listOfFilters, (option) => {
            for (const filter of filtersList) {
                if (filter.value === option) {
                    if (filter.type === 'index') {
                        return filter.value;
                    } else {
                        break
                    }
                }
            }
            return null;
        }).filter(Boolean);
        var arrselectedsectors = Array.from(listOfFilters, (option) => {
            for (const filter of filtersList) {
                if (filter.value === option) {
                    if (filter.type === 'sector') {
                        return filter.value;
                    } else {
                        break
                    }
                }
            }
            return null;
        }).filter(Boolean);

        setSelectedIndices(arrselectedindices)
        setSelectedSectors(arrselectedsectors)
        setSelectedFilters(Array.from(listOfFilters, (option) => option))
    }

    const handleOnlyShowFavoritesChange = (event) => {
        setCheckedFavorites(event.target.checked);
        setOnlyShowFavorites(event.target.checked);
        localStorage.setItem("only-show-fav", event.target.checked ? "true" : "false")
    }

    const handleOnlyShowETFsChange = (event) => {
        setCheckedETFs(event.target.checked);
        setOnlyShowETFs(event.target.checked);
        localStorage.setItem("only-show-etf", event.target.checked ? "true" : "false")
    }

    const resetTopBar = () => {
        setSearchTextField("");
        setSearchQuery("");
        setSelectedFilters([]);
        setSelectedIndices([]);
        setSelectedSectors([]);
        setSortedColumn(null);
        setSortOrder("asc");
        setCurrentPage(1);

    }


    const handleChangeModel = (event) => {
        setSelectedModelLocal(event.target.value)
        setSelectedModel(event.target.value)
    };

    const marks = [
        {
            value: 55.0,
            label: '55%',
        },
        {
            value: 100,
            label: '100%',
        },
    ];
    const hpMarks = [
        {
            value: 1,
            label: '1d',
        },
        {
            value: 30,
            label: '30d',
        },
    ];
    const minPriceMarks = [
        {
            value: -2,
            label: '$.01',
        },
        {
            value: -1,
            label: '$.10',
        },
        {
            value: 0,
            label: '$1',
        },
        {
            value: 1,
            label: '$10',
        },
        {
            value: 2,
            label: '$100',
        },
        {
            value: 3,
            label: '$1000',
        }
    ];

    return (
        <>
            {isMobile ? (
                <>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        // alignItems: "center",
                    }}>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "10px",
                        }}>
                            <Typography variant="h4" sx={{
                                padding: "10px 0px 0px 5px"
                            }}>
                                Settings
                            </Typography>
                            <Box display="flex" justifyContent="flex-end">
                                <CloseIcon onClick={() => setSettingsOpen(false)} className="drawer-close" />
                            </Box>

                        </Box>
                        <TextField
                            label="Company Name"
                            sx={{
                                color: 'white',
                                marginRight: 2,
                                flex: 1,
                                margin: "10px",
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: "16px",
                                    bgcolor: "white",
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add shadow
                                    '& fieldset': {
                                        border: 'none', // Remove border
                                    },
                                },
                            }}
                            value={searchTextField}
                            onChange={handleSearchInputChange}
                        />
                        <FormControl
                            variant="outlined"
                            sx={{
                                height: "56px",
                                flex: 1,
                                marginTop: "10px"
                            }}
                        >
                            <InputLabel>Filter</InputLabel>
                            <Select
                                multiple
                                label="Filter"
                                autoWidth={true}
                                value={selectedFilters}
                                onChange={handleFilterChange}
                                MenuProps={{ disableScrollLock: true }}
                                renderValue={(selected) => (
                                    <div
                                        style={{
                                            maxHeight: '56px', // Set the maximum height to trigger the scroll bar
                                            overflowY: 'auto', // Enable vertical scrolling when content exceeds maxHeight
                                        }}
                                    >
                                        <Box
                                            sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, overflowY: 'auto' }}
                                        >
                                            {selected.map((value) => (
                                                <Chip
                                                    key={value}
                                                    label={value}
                                                    sx={{
                                                        bgcolor: "rgba(0, 53, 102, 255)",
                                                        color: "white",
                                                    }}
                                                />
                                            ))}
                                        </Box>
                                    </div>
                                )}
                                sx={{
                                    bgcolor: "white",
                                    borderRadius: "16px",
                                    height: "100%",
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add shadow
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none', // Remove border
                                    },
                                }}
                            >
                                {filtersList.map((filter) => (
                                    <MenuItem key={filter.value} value={filter.value}>
                                        {filter.value + (filter.type === 'index' ? " Cos" : "")}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Box sx={{
                            padding: "10px",
                        }}>
                            <RegimeToggle />
                            <div style={{ width: "40%", alignItems: "center" }}>
                                <HistoricalPeriodSlider period={period} setPeriod={setPeriod} />
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", width: "50%", alignItems: "center", marginTop: "20px" }}>
                                <div style={{ marginBottom: '0px' }}>
                                    {hp <= 30 ? `Hold: ${hp}d` : `Hold: ${hp / 5 - 5}m`}
                                </div>
                                <div style={{ width: "100%" }}>
                                    <Slider
                                        min={1}
                                        max={55}
                                        value={hpRaw}
                                        valueLabelDisplay="off"
                                        color="warning"
                                        onChange={(e) => setHpRaw(e.target.value)}
                                        onChangeCommitted={handleHpCommit}
                                        marks={hpMarks}
                                        step={null}
                                        sx={{
                                            '& .MuiSlider-markLabel': {
                                                color: "transparent",
                                                fontSize: 7,
                                            },
                                        }}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                        </Box>

                        <Box sx={{
                            padding: "10px",
                        }}>
                            <Box component="div" fontSize={15} color="black" sx={{
                                padding: "10px 0px"
                            }}>
                                Model:
                            </Box>
                            <FormControl fullWidth>
                                <InputLabel>Model</InputLabel>
                                <Select
                                    value={selectedModel}
                                    label="Model"
                                    onChange={handleChangeModel}
                                    MenuProps={{ disableScrollLock: true }}
                                >
                                    {models.map((model) => (
                                        <MenuItem key={model.id} value={model.id}>
                                            {model.model_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>

                        <Box sx={{
                            padding: "10px",
                        }}>
                            <Box component="div" fontSize={15} color="black">
                                Minimum Equity Price
                            </Box>

                            <Box sx={{
                                padding: "0 20px"
                            }}>
                                <Slider
                                    aria-label="Volume"
                                    min={-2}
                                    max={3}
                                    step={.01}
                                    value={Math.log10(minPriceRaw)}
                                    valueLabelDisplay="auto"
                                    scale={x => `$${Math.pow(10, x).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]}`}
                                    onChange={handleMinPriceChange}
                                    onChangeCommitted={handleMinPriceCommit}
                                    marks={minPriceMarks}
                                    sx={{
                                        padding: "20px",
                                    }}
                                />
                            </Box>

                        </Box>


                        <Box
                            sx={{
                                display: "flex",
                                flex: 1,
                                gap: "0px"
                            }}
                        >
                            <button onClick={resetTopBar}>Reset</button>
                        </Box>

                    </Box>
                </>
            ) : (
                <Box sx={{
                    padding: "15px",
                    marginBottom: "1px",
                    borderRadius: "0px",
                    backgroundColor: "rgb(243, 243, 243)",
                    display: "block",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginLeft: "0px",
                    marginRight: "0px"
                }}>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",

                    }}>
                        <Box
                            sx={{
                                display: "flex",
                                flex: 1
                            }}
                        >
                            <TextField
                                label="Company Name"
                                sx={{
                                    color: 'white',
                                    marginRight: 2,
                                    flex: 1,
                                    margin: "10px",
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: "16px",
                                        bgcolor: "white",
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add shadow
                                        '& fieldset': {
                                            border: 'none', // Remove border
                                        },
                                    },
                                }}
                                value={searchTextField}
                                onChange={handleSearchInputChange}
                            />
                            <FormControl
                                variant="outlined"
                                sx={{
                                    height: "56px",
                                    flex: 1,
                                    marginTop: "10px"
                                }}
                            >
                                <InputLabel>Filter</InputLabel>
                                <Select
                                    multiple
                                    label="Filter"
                                    autoWidth={true}
                                    value={selectedFilters}
                                    onChange={handleFilterChange}
                                    MenuProps={{ disableScrollLock: true }}
                                    renderValue={(selected) => (
                                        <div
                                            style={{
                                                maxHeight: '56px', // Set the maximum height to trigger the scroll bar
                                                overflowY: 'auto', // Enable vertical scrolling when content exceeds maxHeight
                                            }}
                                        >
                                            <Box
                                                sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, overflowY: 'auto' }}
                                            >
                                                {selected.map((value) => (
                                                    <Chip
                                                        key={value}
                                                        label={value}
                                                        sx={{
                                                            bgcolor: "rgba(0, 53, 102, 255)",
                                                            color: "white",
                                                        }}
                                                    />
                                                ))}
                                            </Box>
                                        </div>
                                    )}
                                    sx={{
                                        bgcolor: "white",
                                        borderRadius: "16px",
                                        height: "100%",
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add shadow
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none', // Remove border
                                        },
                                    }}
                                >
                                    {filtersList.map((filter) => (
                                        <MenuItem key={filter.value} value={filter.value}>
                                            {filter.value + (filter.type === 'index' ? " Cos" : "")}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flex: 1,
                                gap: "0px"
                            }}
                        >
                            <button onClick={resetTopBar}>Reset</button>
                        </Box>

                        <FormGroup>
                            <Box>
                                <button onClick={() => setOpenModal(true)}><FaGear style={{ marginTop: 5 }} /></button>
                                <Modal
                                    open={openModal}
                                    onClose={() => setOpenModal(false)}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        position: 'fixed',
                                    }}
                                >
                                    <div style={{
                                        width: "30%",
                                        backgroundColor: "white",
                                        padding: "40px",
                                        borderRadius: "30px",
                                        textAlign: "center",
                                        maxHeight: "80vh",
                                        overflowY: "auto"
                                    }}>
                                        <h3>Settings</h3>
                                        <AnalystSelectModal />
                                        <p>Model:</p>
                                        <FormControl fullWidth>
                                            <InputLabel>Model</InputLabel>
                                            <Select
                                                value={selectedModel}
                                                label="Model"
                                                onChange={handleChangeModel}
                                                MenuProps={{ disableScrollLock: true }}
                                            >
                                                {models.map((model) => (
                                                    <MenuItem key={model.id} value={model.id}>
                                                        {model.model_name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                        <p>Minimum Equity Price</p>
                                        <Slider
                                            aria-label="Volume"
                                            min={-2}
                                            max={3}
                                            step={.01}
                                            value={Math.log10(minPriceRaw)}
                                            valueLabelDisplay="auto"
                                            scale={x => `$${Math.pow(10, x).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]}`}
                                            onChange={handleMinPriceChange}
                                            onChangeCommitted={handleMinPriceCommit}
                                            marks={minPriceMarks}

                                        />
                                        <p>Performance Sort</p>
                                        {["Default", "1 Year", "6 Months", "3 Months", "1 Month", "7 Days", "1 Day"].map(type => (
                                            <button
                                                key={type}
                                                onClick={() => {
                                                    setStateSort(type)
                                                    localStorage.setItem("col-state-sort", type);
                                                }}
                                                className={stateSort === type ? "highlighted-button" : ""}
                                            >
                                                {`${type.charAt(0).toUpperCase() + type.slice(1)}`}
                                            </button>
                                        ))}
                                        <p>Indicator Type</p>
                                        {["EI", "CI", "Both"].map(type => (
                                            <button
                                                key={type}
                                                onClick={() => {
                                                    setStateIndicator(type)
                                                    localStorage.setItem("col-state-indicator", type);
                                                }}
                                                className={stateIndicator === type ? "highlighted-button" : ""}
                                            >
                                                {`${type.charAt(0).toUpperCase() + type.slice(1)}`}
                                            </button>
                                        ))}
                                        <p>Best Horizon Display</p>
                                        {["Display Best Horizon", "Hide"].map(type => (
                                            <button
                                                key={type}
                                                onClick={() => {
                                                    setStateBHorizon(type)
                                                    localStorage.setItem("col-state-BHorizon", type);
                                                }}
                                                className={stateBHorizon === type ? "highlighted-button" : ""}
                                            >
                                                {`${type.charAt(0).toUpperCase() + type.slice(1)}`}
                                            </button>
                                        ))}

                                        <p>Confidence Threshold</p>

                                        <Slider
                                            aria-label="Volume"
                                            min={55.0}
                                            max={100.0}
                                            value={threshold}
                                            valueLabelDisplay="auto"
                                            color="warning" // maybe make a better color in future
                                            onChange={handleThresholdChange}
                                            marks={marks}
                                        />

                                    </div>
                                </Modal>
                            </Box>
                        </FormGroup>

                    </Box>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0px 10px"

                    }}>
                        <FormControlLabel
                            control={
                                <Box display="flex" flexDirection="column" alignItems="center">
                                    <Box component="div" fontSize={15} color="black" mb={1}>
                                        Favorites
                                    </Box>
                                    <Switch
                                        style={{ margin: "0" }}
                                        checked={checkedFavorites}
                                        onChange={handleOnlyShowFavoritesChange}
                                        color="warning"
                                    />
                                </Box>
                            }
                        />
                        <FormControlLabel
                            control={
                                <Box display="flex" flexDirection="column" alignItems="center">
                                    <Box component="div" fontSize={15} color="black" mb={1}>
                                        ETFs
                                    </Box>
                                    <Switch
                                        style={{ margin: "0" }}
                                        checked={checkedETFs}
                                        onChange={handleOnlyShowETFsChange}
                                        color="warning"
                                    />
                                </Box>
                            }
                        />
                        <div className={isLoadingSumPNL ? "blurThreePX" : ""} style={{ position: "relative", flexGrow: 1, margin: "0 10px" }}>
                            {isLoadingSumPNL && <div className="mktps-spinner"></div>}
                            <OverallRow
                                regimeSettings={regimeSettings}
                                threshold={threshold / 100.0}
                                data={overallPnl}
                                isMobile={isMobile}
                                mobilePage={mobilePage}
                                setMobilePage={setMobilePage}
                                state={state}
                                hp={hp}
                                period={period}
                                cumulativePNLArray={cumulativePNLArray}
                            />
                        </div>
                        <RegimeToggle />
                        <TickerOfTheDay
                            selectedIndices={selectedIndices}
                            selectedSectors={selectedSectors}
                        />
                    </Box>
                </Box>
            )}
        </>
    )
}
