import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import useResizeObserver from '@react-hook/resize-observer';
import '../css/d3.css'
import {
    select,
    line,
    curveCardinal,
    scaleLinear,
    axisBottom,
    axisLeft,
    svg,
    scaleTime,
    extent,
    timeMonth,
    timeDay,
    min,
    max,
    symbol,
    symbolCircle,
    symbolsStroke
} from "d3";
import { Tooltip } from 'react-tooltip';




function getFormattedDate(date) {
    return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
}

function createTooltip(d) {
    try {
        return `${d.analystName} <br/>Date: ${getFormattedDate(d.publishedDate)} <br/>Price Target: ${d.priceTarget}`
    }
    catch {
        return `Failed to load information.`
    }
}

const useSize = (target) => {
    const [size, setSize] = useState()
    useLayoutEffect(() => {
        setSize(target.current.getBoundingClientRect())
    }, [target])
    useResizeObserver(target, (entry) => setSize(entry.contentRect))
    return size
}
//chart component
export default function D3PriceTarget(props) {
    //refs
    const svgRef = useRef();
    const boundingRef = useRef();
    const size = useSize(boundingRef)

    //draws chart
    useEffect(() => {
        if (!props.data || !size) {
            return
        }
        const height = size.height
        const width = size.width
        const data = props.data.map((item) => {
            return {
                date: (new Date(item.date)).toISOString().split('T')[0],
                price_change: item.actual_price_change
            }
        })
        const dateRange = extent(data, d => new Date(d.date));
        const priceTargetsData = props.priceTargetsData ? props.priceTargetsData.filter((item) => {
            return (item.publishedDate <= dateRange[1] && item.publishedDate >= dateRange[0])
        }) : []

        const svg = select(svgRef.current);
        svg.selectAll("*").remove();

        //scales
        var scaleOffset = { y: 30, x: 90 }
        const xScale = scaleTime()
            .domain(extent(data, d => new Date(d.date)))
            .range([scaleOffset.y, width - 20]);


        //axes
        const xAxis = axisBottom(xScale).tickFormat(d => getFormattedDate(d)).ticks(9);

        const correctTargetDateDict = {};
        const correctTarget = []
        const incorrectTargetDateDict = {};
        const incorrectTarget = []
        priceTargetsData.forEach(element => {
            const date = element.publishedDate
            const datestr = getFormattedDate(date)
            const latestPriceChange = data
                .filter(item => new Date(item.date) < date)
                .reduce((latest, item) => {
                    const itemDate = new Date(item.date);
                    return itemDate > latest.date ? { date: itemDate, price_change: item.price_change } : latest;
                }, { date: new Date(0), price_change: 1.0 }).price_change;
            if ((element.priceTarget / element.priceWhenPosted - 1.0) * (latestPriceChange - 1.0) > 0) {
                if (correctTargetDateDict[datestr] == undefined) correctTargetDateDict[datestr] = 0
                correctTargetDateDict[datestr] += 1
                correctTarget.push({
                    ...element,
                    count: correctTargetDateDict[datestr]
                })
            }
            if ((element.priceTarget / element.priceWhenPosted - 1.0) * (latestPriceChange - 1.0) < 0) {
                if (incorrectTargetDateDict[datestr] == undefined) incorrectTargetDateDict[datestr] = 0
                incorrectTargetDateDict[datestr] -= 1
                incorrectTarget.push({
                    ...element,
                    count: incorrectTargetDateDict[datestr]
                })
            }
        });
        const yScale = scaleLinear().domain(extent(correctTarget.concat(incorrectTarget), d => d.count))
            .range([height - scaleOffset.y, scaleOffset.x]);
        const yAxis = axisLeft(yScale);

        svg.append('g')
            .selectAll("dot")
            .data(correctTarget)
            .enter()
            .append("path")
            .attr("class", "bubble")
            .attr("d", symbol().type(symbolCircle).size(40))
            .attr("transform", d => `translate(${xScale(new Date(d.publishedDate))},${yScale(d.count)})`)
            .style("fill", "none")
            .style("stroke", "rgba(var(--green-rgb), 0.9)")
            .style("stroke-width", "2")
            .attr("data-tooltip-id", "d3-pnl-tooltip")
            .attr("data-tooltip-html", d => createTooltip(d));

        svg.append('g')
            .selectAll("dot")
            .data(incorrectTarget)
            .enter()
            .append("path")
            .attr("d", symbol().type(symbolCircle).size(40))
            .attr("transform", d => `translate(${xScale(new Date(d.publishedDate))},${yScale(d.count)}) rotate(180)`)
            .style("fill", "none")
            .style("stroke", "rgba(var(--red-rgb), 0.9)")
            .style("stroke-width", "2")
            .attr("data-tooltip-id", "d3-pnl-tooltip")
            .attr("data-tooltip-html", d => createTooltip(d));

        svg.append("g")
            .attr("class", "x-axis")
            .attr("transform", `translate(0,${height - 30})`)
            .call(xAxis);

        const legendData = [
            { label: "Correct Target", color: "rgba(var(--green-rgb), 0.9)" },
            { label: "Incorrect Target", color: "rgba(var(--red-rgb), 0.9)" }
        ];
        const legend = svg.append("g")
            .attr("class", "legend")
            .attr("transform", `translate(80, 20)`);

        const legendItems = legend.selectAll(".legend-item")
            .data(legendData)
            .enter()
            .append("g")
            .attr("class", "legend-item")
            .attr("transform", (d, i) => `translate(${i * 150}, 0)`);

        legendItems.append("path")
            .attr("d", symbol().type(symbolCircle).size(40))
            .style("fill", "none")
            .style("stroke", d => d.color)
            .style("stroke-width", "2");

        legendItems.append("text")
            .attr("x", 15)
            .attr("y", 5)
            .text(d => d.label)
            .style("font-size", "12px")
            .attr("alignment-baseline", "middle");

    }, [props.data, size, props.priceTargetsData]);

    var boundingStyle = { height: "100%", width: "100%" }

    return (
        <div ref={boundingRef} style={boundingStyle}>
            <div style={boundingStyle}>
                <svg ref={svgRef} style={{ padding: "10px", height: "100%", width: "100%" }}>
                </svg>
            </div>
            <Tooltip id="d3-pnl-tooltip" multiline={true} data-html={true} style={{ zIndex: "100" }} />
        </div>
    );
};
