import '../css/product.css';
import { useState, useEffect, useRef } from "react";
import { PublicFooter, PublicTitle, PublicNavbar } from "../components/pagestyle";
import { HomePageChatOnly } from './home';
import { Register } from '../components/header';
import {
    Routes,
    Route,
    Link,
    Navigate,
    useLocation,
    useParams,
    useNavigate
} from "react-router-dom";
import Popup from 'reactjs-popup';
import { HomeCardTemplate } from './home';
import { useMediaQuery, useTheme } from '@mui/material';

export function EnterEmailModal(props) {
    const [email, setEmail] = useState("");
    const [first, setFirst] = useState("");
    const [last, setLast] = useState("");
    const [response, setResponse] = useState("");

    let handleSubmitGeneral = async (e) => {
        e.preventDefault();
        handleSubmit("feedback");
    }

    async function handleSubmit() {
        if (!email) {
            setResponse("Email required to submit");
            return;
        }
        if (!first) {
            setResponse("First Name required to submit");
            return;
        }
        if (!last) {
            setResponse("Last Name required to submit");
            return;
        }
        const validateEmail = (em) => {
            return String(em)
                .toLowerCase().match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        };
        if (email && !validateEmail(email)) {
            setResponse("Valid email required to submit");
            return;
        }
        /*let token = captchaRef.current.getValue();
        if (!token) {
            setResponse("Recaptcha required to submit");
            return;
        }*/
        window.dataLayer.push({'event': 'resource_center_email_access'});
        try {
            let res = await fetch(`/api/public/request-pdf`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email,
                    first: first,
                    last: last,
                    url: window.location.href,
                    //captcha: token
                }),
            });
            //captchaRef.current.reset();
            if (res.status === 200) {
                setEmail("");
                setFirst("");
                setLast("");
                setResponse("Attachments of the requested documents will be sent to your email");
            } else if (res.status === 403 || res.status === "403") {
                setResponse("Error: please try again or contact us directly");
            } else {
                setResponse("Some error occurred");
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="home-chat-modal">
            <h3>Enter Email to Receive BAM Documentation</h3>
            <form className="home-modal-form">
                <input type="text" value={email} id="email" onChange={(e) => setEmail(e.target.value)} placeholder={"Business Email"} />
                <input type="text" value={first} id="first" onChange={(e) => setFirst(e.target.value)} placeholder={"First Name"} />
                <input type="text" value={last} id="last" onChange={(e) => setLast(e.target.value)} placeholder={"Last Name"} />
                {/*<div className="home-onboarding-recaptcha">{recaptchaSiteKey ? <ReCAPTCHA sitekey={recaptchaSiteKey} ref={captchaRef} /> : null}</div>*/}
                <button onClick={handleSubmitGeneral}>Submit</button>
                <div className="message">{response ? <p>{response}</p> : null}</div>
            </form>
        </div>
    );
}

function ProductCard(props) {

    var text = "";
    if (props.title === "Infographic") {
        text = "The scope and rigor behind BAM's proprietary Long Short-Term Memory (LSTM) model go beyond existing financial LSTMs to provide simple, unique, and verifiable signals. Understand our comprehensive process with this infographic."
    } else if (props.title === "Solution Brief") {
        text = "BAM's Marketpulse is designed to provide trading intelligence that's simple, unique, verifiable and proven. In a financial landscape where data is rapidly evolving, top traders and asset managers can stay ahead of the market with dynamic equity and credit signals. These signals come with statistical confidence, performance relative to indices, and sentiment in comparison to similar companies."
    } else if (props.title === "Historical Performance") {
        text = "Explore the historical performance of BAM's AdaptiveSignal with straightforward charts that illustratereturns when trades are executed and unwound using our proprietary Long Short-Term Memory(LSTM) and Natural Language Processing (NLP) models over a 15-day trading period in a normalmarket regime."
    }

    return (
        <div style={{ maxWidth: "20vw" }}>
            <h2>{props.title}</h2>
            <p style={{ whiteSpace: "normal" }}>{text}</p>
        </div>
    )
}

function ResourceCenter(props) {

    function handleOpenPDF(type = "") {
        window.dataLayer.push({'event': 'resource_center_open_pdf'});
        window.location.href = `/api/public/pdf-documents?type=${type}`;
    }
    const navigate = useNavigate();

    function handlePageChange(url) {
        window.dataLayer.push({'event': 'resource_center_open_page'});
        navigate(url);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));    

    return (
        <div className={isMobile ? "home-card-container-mobile" : "home-card-container"}>
            <button onClick={props.disabled ? null : (() => handleOpenPDF("onepager"))} className="escape-button">
                <HomeCardTemplate
                    title="TradingPulse One-pager Brief"
                    shown="TradingPulse's objective-driven AI, ML, and human expertise transforms complex market and corporate data into clear signals that support, spot..."
                    triggered
                />
            </button>
            <button onClick={props.disabled ? null : (() => handleOpenPDF("brief"))} className="escape-button">
                <HomeCardTemplate
                    title="Solution Brief: Elevated Trading Intelligence"
                    shown="Tradingpulse provides clear, unique, and proven trading intelligence. Stay ahead with dynamic signals tailored for top traders and asset managers."
                    triggered
                />
            </button>
            <button onClick={props.disabled ? null : (() => handleOpenPDF("historical"))} className="escape-button">
                <HomeCardTemplate
                    title="Historical Performance Charts with Adaptive Signals"
                    shown="Explore historical performance with clear charts that showcase returns when trades are executed and unwound using BAM's proprietary LSTM model."
                    triggered
                />
            </button>
            <button onClick={props.disabled ? null : (() => handleOpenPDF("infographic"))} className="escape-button">
                <HomeCardTemplate
                    title="BAM's Advanced LSTM Model Explained"
                    shown="Our proprietary LSTM model offers clear, unique signals surpassing traditional financial models. Explore our process in this concise infographic."
                    triggered
                />
            </button>
            {props.complete ?
                <>
                    {window.location.pathname !== "/resources/trading-wisdom-whole-greater-than-sum-parts" &&
                    <button onClick={() => handlePageChange("/resources/trading-wisdom-whole-greater-than-sum-parts")} className="escape-button">
                        <HomeCardTemplate
                            title="Trading Wisdom: The Whole Is Greater Than the Sum of Its Parts"
                            shown="In the fast-paced world of trading, professionals are inundated with a wealth of data from various sources. "
                            triggered
                        />
                    </button>}
                    {window.location.pathname !== "/resources/realities-artificial-intelligence-induced-financial-noise-how-mitigate" &&
                    <button onClick={() => handlePageChange("/resources/realities-artificial-intelligence-induced-financial-noise-how-mitigate")} className="escape-button">
                        <HomeCardTemplate
                            title="Realities of AI-Induced Financial Noise and How To Mitigate It"
                            shown="Artificial Intelligence is hailed as the harbinger of efficiency and informed decision-making in financial markets,"
                            triggered
                        />
                    </button>}
                    {(window.location.pathname !== "/resources/navigating-low-signal-noise-ratio-space-data-financial-markets") && window.location.pathname !== "/resources" &&
                    <button onClick={() => handlePageChange("/resources/navigating-low-signal-noise-ratio-space-data-financial-markets")} className="escape-button">
                        <HomeCardTemplate
                            title="Navigating a Low Signal-to-Noise Ratio Space: Data in Financial Markets"
                            shown="In the dynamic world of financial markets, where information flows at an increasing rate, decision-makers face a daunting challenge"
                            triggered
                        />
                    </button>}
                    
                </>
                : null}
            {/*
            <button onClick={props.disabled ? null : (() => navigate('/all-pdf'))} className="escape-button">
                <HomeCardTemplate
                    title="View All Historical Resources"
                    shown="See all past versions of whitepapers, briefs, and infographics that have been published. "
                    triggered
                />
            </button>
            */}
        </div>
    )
}

function RelatedArticles(props) {
    const [username, setUsername] = useState("");
    const [loggedIn, setLoggedIn] = useState(false);

    const checkIfLoggedIn = () => {
        if (("Yes" == localStorage.getItem("user-is-logged-in-bam-money")) 
            && (Date.now() - (localStorage.getItem("user-time-log-in-bam-money") ? localStorage.getItem("user-time-log-in-bam-money") : 0) < 120000)) {
            setUsername(localStorage.getItem("username-bam-money"))
            setLoggedIn(true);
            return;
        }
        fetch('/api/account', {})
            .then((response) => response.json())
            .then((json) => {
                if (json.response === "not registered") {
                    setLoggedIn(true);
                }
                if (json.response === "registered") {
                    setUsername(json.name);
                    setLoggedIn(true);
                } else setUsername("")
            })
            .catch(() => setUsername(""));
    }

    useEffect(() => {
        checkIfLoggedIn();
      }, []);

    return (
        <>
            {props.title ? <h1>Related Articles</h1> : null}
            <div style={{ minHeight: "40vh", padding: "0 10vh" }}>
                {!loggedIn ?
                    <Popup

                        trigger={open => (
                            <div><ResourceCenter disabled complete/></div>
                        )}
                        position="right center"
                        closeOnDocumentClick
                        modal
                    >
                        <EnterEmailModal />
                    </Popup> :
                    <ResourceCenter complete/>}
            </div>
        </>
    )
}

function ProductArticle(props) {
    const articleContainer = useRef(null);
    const readMore = useRef(null);

    function handleReadMore() {
        articleContainer.current.style.height = "fit-content";
        readMore.current.style.opacity = 0;
    }

    return (
        <div className="product-article-container" ref={articleContainer}>
            <h1>{props.title}</h1>
            <p>By <span style={{ color: "blue" }}>{props.author}</span></p>
            <img src={props.image} alt="" />
            <p style={{ fontSize: "10px", margin: "none" }}>{props.caption}</p>
            {props.content}
            <p className="readmore" ref={readMore} onClick={handleReadMore}>Read More</p>
        </div>
    );
}

export default function Product() {
    window.document.title = "BAM.money | Resource Center";

    let location = useLocation();

    return (
        <>
            <PublicNavbar />
            <PublicTitle title="Resource Center" />
            <Routes location={location}>
                <Route path="/" element={
                    <>
                        <RelatedArticles complete />
                        <div className="home-display-page dark" style={{ minHeight: "0", height: "fit-content", padding: "40px 10vw" }}>
                            <div>
                                <h1>Clear company signals, faster</h1>
                                <h3>Start your free trial to see how BAM transforms complex data into clear signals that can unlock alpha for you.</h3>
                                <div style={{ transform: "scale(1.5)" }}><Register text="Try it Free" highlight /></div>
                            </div>
                        </div>
                    </>
                } />
            </Routes>
            <Routes location={location}>
                <Route path="/realities-artificial-intelligence-induced-financial-noise-how-mitigate" element={<ProductArticle
                    title="Realities of AI-Induced Financial Noise and How To Mitigate It"
                    author="Geraldo Filgueiras"
                    image="/images/nivenn-lanos-TgzFdow4HBQ-unsplash.jpg"
                    caption={<>Picture by <a href="https://unsplash.com/@nivenn">Nivenn Lanos</a></>}
                    content={<>
                        <p>Artificial Intelligence is hailed as the harbinger of efficiency and informed decision-making in financial markets, but it's not immune to scrutiny and concern is growing. Is AI inadvertently contributing to an escalation in financial noise, creating complexities that hinder rather than aid decision-making? This concern demands a nuanced exploration, weighing the promises of AI against the potential challenges it may pose.</p>
                        <h3>Financial Noise Is On The Rise</h3>
                        <p>AI has undeniably revolutionized the landscape of financial decision-making. However, the same technologies that promise clarity and insight are contributing to increasing financial noise. The sheer volume of data AI systems process, often including unconventional and alternative sources, presents a double-edged sword. While it opens doors to new insights, it also raises concerns about information overload. Decision-makers grapple with distinguishing between meaningful signals, falsehoods and the cacophony of irrelevant data, potentially leading to confusion,suboptimal decision-making and even wrong decisions.</p>
                        <h3>Algorithmic Trading and Market Sensitivity</h3>
                        <p>One area where AI's impact is pronounced is in algorithmic trading. While these algorithms promise efficient, emotion-free trading, they also introduce a level of market sensitivity that can contribute to noise and fake data like spoofing. Rapid, automated responses to market events may result in sudden price movements, challenging the traditional understanding of market dynamics and introducing unpredictability.</p>
                        <h3>The Pitfalls of Imperfect Sentiment Analysis</h3>
                        <p>AI-driven sentiment analysis, another promising avenue, is not without its challenges. The interpretation of market sentiment is intricate and nuanced, often reliant on contextual understanding that AI systems may struggle to grasp. Inaccuracies in sentiment analysis can lead to misguided decisions, adding a layer of noise to the decision-making process.</p>
                        <h3>Striking a Balance by Mitigating Noise through AI</h3>
                        <p>Addressing the concern of AI-induced financial noise requires a delicate balance. Striking this equilibrium involves implementing AI systems with a keen awareness of their limitations, refining algorithms to enhance adaptability, and acknowledging the evolving nature of market dynamics.</p>
                        <ol type="1">
                            <li><strong>Quality Data Matters:</strong>
                                <ol type="a">
                                    <li>Emphasize the importance of using high-quality training data and filtering to enhance the accuracy of your AI systems.</li>
                                    <li>Exercise caution in incorporating unconventional data sources, ensuring their relevance and reliability for your decision-making.</li>
                                </ol>
                            </li>
                            <li><strong>Robust Model Calibration:</strong>
                                <ol type="a">
                                    <li>Ensure your AI models are regularly calibrated to align with changing market conditions.</li>
                                    <li>Fine-tune your algorithms to filter out irrelevant information and adapt to evolving patterns.</li>
                                </ol>
                            </li>
                            <li><strong>Transparency and Backtesting:</strong>
                                <ol type="a">
                                    <li>Require transparency in your algorithmic trading strategies to mitigate sudden, unexpected market movements, verify historical backtesting exercises.</li>
                                    <li>Implement safeguards to prevent your algorithmic errors from cascading into broader market volatility.</li>
                                </ol>
                            </li>
                            <li><strong>Continuous Human Oversight:</strong>
                                <ol type="a">
                                    <li>Interprets nuances, subtleties and intricacies that AI may struggle to grasp in the decision-making process.</li>
                                    <li>Identifies and mitigates potential algorithmic biases to improve accuracy and reliability.</li>
                                </ol>
                            </li>
                        </ol>
                        <h3>Conclusion: Balance Your Approach to Informed Decision-Making</h3>
                        <p>While concerns about AI-induced financial noise are valid, it is crucial to view them through a lens of cautious optimism. AI, when implemented thoughtfully and continuously refined, can be a powerful ally in the quest for informed decision-making. Striking the right balance between harnessing the potential of AI and mitigating its unintended consequences is key to ensuring that the financial symphony plays harmoniously, not discordantly. As the financial landscape evolves, the journey towards clarity in decision-making involves navigating the complexities with a discerning eye on both the promises and pitfalls of AI.</p>
                    </>}
                />} />
                <Route path="/trading-wisdom-whole-greater-than-sum-parts" element={<ProductArticle
                    title="Trading Wisdom: The Whole Is Greater Than the Sum of Its Parts"
                    author="Geraldo Filgueiras"
                    image="/images/shubham-dhage-UxDU0Gg5pqQ-unsplash-min-scaled.jpeg"
                    caption={<>Picture by <a href="https://unsplash.com/@theshubhamdhage">Shubham Dhage</a></>}
                    content={<>
                        <p>In the fast-paced world of trading, professionals are inundated with a wealth of data from various sources. From historical pricing and fundamental metrics to market and technical data, sentiment, and risk metrics, the challenge lies not in the availability of information but in deciphering its collective significance. Success in today's dynamic markets requires trading professionals to transcend the individual components and grasp the holistic view - the sum vs. the parts.</p>
                        <h3>The Complexity of Trading Data</h3>
                        <p>Historical pricing, fundamental indicators, market trends, technical analysis, market sentiment, and risk metrics – each element provides a unique perspective on market dynamics. Trading professionals often find themselves immersed in the intricacies of these individual parts, striving to extract meaningful insights. However, the true power lies in understanding how these components interact and contribute to the broader market narrative with lightening speed.</p>
                        <h3>The Holistic Approach</h3>
                        <p>To navigate the complexities of trading data effectively, professionals must adopt a holistic approach. It's not merely about interpreting historical pricing trends or analyzing fundamental metrics in isolation. Instead, it's about synthesizing these elements to form a comprehensive understanding of market behavior. The sum is greater than its parts, and recognizing the interconnectedness of various data points is key to making informed decisions.</p>
                        <h3>The Role of Black-Boxes</h3>
                        <p>In an era where algorithms and black-box trading systems are pervasive, there's a natural inclination to approach them with skepticism. However, these sophisticated systems, with their intricate algorithms and guiding principles, can be powerful allies in deciphering the sum vs. the parts. Rather than viewing them as impenetrable enigmas, understanding the underlying principles of these black-boxes can illuminate the broader market dynamics they are designed to capture.</p>
                        <h3>Transparency is the Bridge</h3>
                        <p>Trust in black-box systems is built on its historical transparency. But trust without verification can be quite detrimental and players know how cherry picking can transform black-boxes into useless tools. Trading professionals should strive to comprehend the foundational principles driving these algorithms and be confident that no cherry picking is used. This can be done through immutable technologies. By demystifying the black-box, one can see beyond the complexity and appreciate how it factors in historical pricing, fundamental indicators, market trends, sentiment, and risk metrics. This transparency bridges the gap between the intricate components and the holistic market view.</p>
                        <h3>Striking a Balance</h3>
                        <p>In the quest to see the sum vs. the parts, trading professionals must strike a balance between manual analysis and leveraging advanced algorithms. While human intuition and experience play a vital role, technological tools can augment decision-making processes by swiftly processing vast datasets and identifying nuanced patterns.</p>
                        <h3>Conclusion</h3>
                        <p>In the ever-evolving landscape of trading, success hinges on the ability to see the sum vs. the parts. The synergy of historical pricing, fundamental metrics, market trends, technical analysis, sentiment, and risk metrics forms the comprehensive tapestry of market behavior. Embracing this holistic perspective, and understanding the guiding principles behind black-box systems, empowers trading professionals to navigate complexity and make decisions that transcend the individual components. In an era where data and particularly noise are abundant, seeing the sum is the true art of trading.</p>
                    </>}
                />} />
                <Route path="/*" element={<ProductArticle
                    title="Navigating a Low Signal-to-Noise Ratio Space: Data in Financial Markets"
                    author="Geraldo Filgueiras"
                    image="/images/maxim-berg-Ac02zYZs22Y-unsplash.jpg"
                    caption={<>Picture by <a href="https://unsplash.com/@maxberg">Maxim Berg</a></>}
                    content={<>
                        <p>In the dynamic world of financial markets, where information flows at an increasing rate, decision-makers face a daunting challenge – the low signal-to-noise ratio in trading data. This ratio, representing the proportion of valuable signals to extraneous noise, is a critical determinant of decision-making accuracy. In this exploration, we delve into the intricacies of this ratio, dissecting its impact on decision-makers striving to discern meaningful patterns amid the cacophony of information.</p>
                        <h3>Deciphering Signal-to-Noise Ratio:</h3>
                        <p>In financial markets, a low signal-to-noise ratio acts as a formidable hurdle for traders and investors. This challenge arises when the valuable signals, representing meaningful information, are obscured by the noise - an amalgamation of market rumors, speculative trading, macroeconomic events, and other external factors. Effectively navigating this environment requires a keen understanding of how to sift through the noise to extract crucial insights.</p>
                        <h3>Sources of Noise:</h3>
                        <p>The diversity of noise sources in financial trading data adds layers of complexity to the decision-making process. Market rumors, speculative activities, including fake orders and “alternative facts”, and macroeconomic events contribute to the intricate web of noise, making it imperative for decision-makers to identify and filter through these sources.</p>
                        <h3>Measurement:</h3>
                        <p>Efficiently assessing the signal-to-noise ratio demands the application of statistical methods, an  understanding of space and constantly updating tools and frameworks. These tools and frameworks are instrumental in distinguishing meaningful trends from random fluctuations, providing decision-makers with a clearer understanding of the market landscape.</p>
                        <h3>Impact on Decision-Making:</h3>
                        <p>The potential pitfalls of decision-making in the face of a low signal-to-noise ratio are profound. Decisions based on misleading signals or succumbing to the noise can lead to suboptimal outcomes. Unraveling the complexities of this impact is crucial for decision-makers striving to make informed choices.</p>
                        <h3>Technological Solutions:</h3>
                        <p>Advancements in technology offer a ray of hope in mitigating the challenges posed by a low signal-to-noise ratio. Machine learning algorithms, artificial intelligence, and data filtering techniques aim to enhance the extraction of meaningful signals from the overwhelming noise, providing decision-makers with powerful tools for analysis.</p>
                        <h3>Behavioral Considerations:</h3>
                        <p>Beyond technological solutions, understanding the behavioral aspects is paramount. Cognitive biases and emotional responses can be magnified in an environment of uncertainty, influencing trading strategies and market dynamics. Acknowledging and managing these behavioral considerations is integral to effective decision-making.</p>
                        <h3>Risk Management Strategies:</h3>
                        <p>In the face of a low signal-to-noise ratio, robust risk management strategies become the cornerstone of successful decision-making. Diversification, setting clear risk parameters, and implementing adaptive strategies that account for inherent uncertainties are vital components of a resilient risk management framework.</p>
                        <h3>Role of Big Data:</h3>
                        <p>Big Data plays a pivotal role in mitigating the challenges posed by a low signal-to-noise ratio. Integrating vast datasets, including alternative and unconventional sources, contributes to a more nuanced understanding of market dynamics, enabling decision-makers to make informed choices.</p>
                        <p>By unraveling the complexities inherent in a low signal-to-noise ratio environment, decision-makers gain insights and strategies to navigate the vast sea of information. This knowledge empowers them to approach financial decision analysis with harmony and precision in the midst of an ever increasing barrage of financial market data.</p>
                    </>}
                />} />
            </Routes>
            <div style={{ height: "30px" }}></div>
            <Routes location={location}>
                <Route path="/" element={<HomePageChatOnly />} />
                <Route path="/*" element={<RelatedArticles title />} />
            </Routes>
            <PublicFooter />
        </>
    );
};